import React from 'react';
import ReactDOM from 'react-dom';
import OlOverlay from 'ol/Overlay';
import './forest_data_map_layer_popover.css';
import OlLayerTile from "ol/layer/Tile";
import { NoneType } from 'ol/expr/expression';
import { CloseOutlined } from '@ant-design/icons';

// import { getLayerConfig } from './utils';

const fetch = require('sync-fetch')

class ForestDataMapPopover extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            feature_attributes: [],
            selectedDatasets: props.selectedDatasets
        }
        this.handleClose = this.handleClose.bind(this);
        this.getLayerConfig = this.getLayerConfig.bind(this);
    }

    handleClose(event) {
        this.popup.setPosition(undefined);
        event.target.blur();
        return false;
    }

    componentDidMount() {
        // setup popup
        this.popup = new OlOverlay({
            element: ReactDOM.findDOMNode(this).querySelector('#popup')
        });

        let map = this.props.map;
        if (map === undefined) {
            console.log("in popover: no map was given");
            map = this.props.mapRef.current.getMap()
        }

        let layers = this.props.layers;
        if (layers === undefined) {
            layers = this.props.mapRef.current.getLayers()
        }

        map.addOverlay(this.popup);

        var thisObject = this;
        map.on('singleclick', evt => {
            layers = [];
            map.getLayers().forEach(function (layer) {
                if (layer instanceof OlLayerTile) {
                    let urls = layer.getSource().getUrls().toString();
                    if (layer.getSource().getParams) {
                        layers.push(layer);
                    }
                }
            });

            var feature_attributes = [];
            const viewResolution = map.getView().getResolution();
            for (var i = 0; i < layers.length; i++) {
                if (layers[i].getVisible()) {
                    const urls = layers[i].getSource().getUrls();
                    const layer_name = layers[i].getSource().getParams().LAYERS;
                    const url = layers[i].getSource().getFeatureInfoUrl(
                        evt.coordinate,
                        viewResolution,
                        map.getView().getProjection(),
                        {'INFO_FORMAT': 'application/json'}
                    );
                    if (url) {
                        var responseJSON = fetch(url).json();
                        if (responseJSON.features &&
                            responseJSON.features.length > 0 &&
                            responseJSON.features[0].properties) {
                            feature_attributes.push({
                                url: urls.toString(),
                                layer_name,
                                features: responseJSON.features[0].properties
                            })
                            thisObject.setState({
                                feature_attributes
                            });
                            thisObject.popup.setPosition(evt.coordinate);
                        }
                    }
                }
            }
        });

    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedDatasets.length !== this.state.selectedDatasets.length) {
            this.setState({
                selectedDatasets: [...this.props.selectedDatasets]
            });
        }
    }


    getValueRange(item) {
        for (const [key, value] of Object.entries(this.props.valueRanges)) {
            if (item.url + "/" + item.layer_name === key) {
                return value;
            }
        }

        const layerConfig = this.getLayerConfig(item.url, item.layer_name);
        if (layerConfig.type === 'raster') {
            return [layerConfig.min, layerConfig.max];
        }
        return [-10000, 10000];
    }

    getLayerConfig(url, layer) {
        for (let i = 0; i < this.state.selectedDatasets.length; i++) {
            let dataset = this.state.selectedDatasets[i];
            if (dataset.mapping[0].layer === layer && dataset.mapping[0].url === url) {
                return dataset.mapping[0];
            }
        }
        return null;
    }

    vectorColNames(val) {
        switch(val){
            case 'agency':
                return "Agency";
            case 'county':
                return "County";
            case 'activity_description':
                return "Activity Description";
            case 'activity_quantity':
                return "Acres Treated";
            case 'broad_vegetation_type':
                return "Vegetation Type";
            case 'activity_end':
                return "Activity End Date";
            case 'entity_type':
                return "Entity Type";
            case 'administering_org':
                return "Administering Organization";
            case 'primary_ownership_group':
                return "Land Owner";
            case 'activity_uom':
                return "Treatment UOM";
            case 'activity_cat':
                return "Treatment Category";
            case 'region':
                return "Region";
            case 'objectid':
                return "Treatment ID";
            case 'activity_status':
                return "Status";
            default:
                return val
        }
    }

    
    getCategoryValue = (category) => {
        if (category === "GRAZING") {
            return "Grazing";
        } else if (category === "NOT_DEFINED") {
            return "Not Defined";
        } else if (category === "SANI_SALVG") {
            return 'Sanitation & Salvage'
        } else if (category === "MECH_HFR") {
            return "Mechanical and Hand Fuels Reduction";
        } else if (category === "TIMB_HARV") {
            return "Timber Harvest";
        } else if (category === "LAND_PROTEC") {
            return "Land Protection";
        // } else if (category === "Watershed & Habitat Improvement") {
        //     return "WATSHD_IMPRV";
        } else if (category === "BENEFICIAL_FIRE") {
            return "Beneficial Fire";
        } else if (category === "TREE_PLNTING") {
            return "Tree Planting";
        } else if(category === ''){
            return "Not Reported";
        }else {
            return null;
        }
    }

    getOwnerValue = (owner) => {
        if (owner === "FEDERAL") {
            return "Federal";
        } else if (owner === "LOCAL") {
            return "Local";
        } else if (owner === "NGO") {
            return "NGO";
        } else if (owner === "PRIVATE_INDUSTRY") {
            return "Private Industry";
        } else if (owner === "PRIVATE_NON-INDUSTRY") {
            return "Private Non-Industry";
        } else if (owner === "STATE") {
            return "State";
        } else if (owner === "TRIBAL") {
            return "Tribal";
        } else if (owner === "") {
            return "Not Reported";
        }  else {
            return null;
        }
    }

    getAgencyValue = (agency) => {
        if (agency === "CALEPA") {
            return "CA Environmental Protection Agency";
        } else if (agency === "CALSTA") {
            return "CA State Transportation Agency";
        } else if (agency === "CNRA") {
            return "CA Natural Resources Agency";
        } else if (agency === "DOD") {
            return "Department of Defense";
        } else if (agency === "DOI") {
            return "Department of the Interior";
        } else if (agency === "USDA") {
            return "Department of Agriculture";
        } else if (agency === "CARB"){
            return "CA Air Resources Board"        
        } else if (agency === 'Timber Companies') {
            return 'Timber Companies'
        } else if (agency === "OTHER") {
            return "Other";
        } else if (agency === '') {
            return "Not Reported"
        } else {
            return null;
        }
    }

    render() {
        let string = `<table style="table-layout: fixed;"><tbody>`;
        let displayed = false;
        this.state.feature_attributes.map((item, i) => {
            const layerConfig = this.getLayerConfig(item.url, item.layer_name);
            if (layerConfig !== null)
            {
                if (layerConfig.type === 'raster') {
                    const valueRange = this.getValueRange(item);
                    console.log("valueRange here")
                    console.log(valueRange)
                    for (let [, value] of Object.entries(item.features)) {
                        console.log(value)
                        if (value >= valueRange[0] && value <= valueRange[1]) {
                            string += `<tr><td style="word-wrap:break-word; font-weight: bold; padding-right: 10pt; text-align: left;">${layerConfig.name}:</td>`;
                            string += `<td style="white-space: nowrap; color: darkgreen;">${value}</td></tr>\n`;
                            displayed = true;
                            break;
                        }
                    }
                } else if (layerConfig.type === 'vector') {
                    string += `<tr><td style="font-weight: bold; padding-right: 10pt; text-align: left;">${layerConfig.name}:</td><td></td></tr>`;
                    string += `<tr><td colspan="2" style="padding-left: 10pt;"><table>`
                    for (let [key, value] of Object.entries(item.features)) {                        
                        if (key !== 'geo' && key !== 'geo_3310' && key.includes('email') === false && key.includes('SHAPE') === false) {
                            if (key === 'activity_end' && value){
                                value = new Date(value).toLocaleDateString('en-US')
                            } else if (key === 'activity_cat' && value){
                                value = this.getCategoryValue(value)                                
                            } else if (key === 'agency' && value){
                                value = (this.getAgencyValue(value))
                            } else if (key === 'primary_ownership_group' && value){
                                value = (this.getOwnerValue(value))
                            }
                            
                            string += `<tr><td style="font-weight: normal; padding-right: 10pt; text-align: left">${this.vectorColNames(key)}:</td><td style="white-space: nowrap; color: darkgreen;">${value ? value : ''}</td></tr>`;
                            displayed = true;
                        }
                    }
                    string += `</td></td></table>`
                }
        }

            return null;
        });
        string += "</tbody></table>";

        if (!displayed && this.popup) {
            this.popup.setPosition(undefined);
        }

        return (
            <div>
                <div id="popup" className="ol-popup">
                    <a href="#" id="popup-closer" className="ol-popup-closer" onClick={this.handleClose}></a>
                    <div id="popup-content">
                        <div style={{
                            padding: '14px 10pt 10pt 5pt',
                            fontSize: '9pt',
                            whiteSpace: 'nowrap',
                            maxHeight: 250,
                            maxWidth: 480,
                            overflow: 'scroll',
                        }}>
                            <div dangerouslySetInnerHTML={{__html: string}}/>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default ForestDataMapPopover;



