import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import {DownloadOutlined, LoadingOutlined} from '@ant-design/icons';
// import {process} from 'react-scripts@5.0.1'
import { Tooltip, notification, Modal, Form, Input, Button, Spin, Checkbox } from 'antd';
import OlOverlay from 'ol/Overlay';
import './forest_data_map_layer_popover.css';
import { getLayerConfig } from './utils';
import config from './config';
import { json } from 'react-router-dom';


class ForestDataMapDownload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            feature_attributes: [],
            hover: false, 
            visible: false,
            download_loading: false
        }
        this.onDownload = this.onDownload.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.toggleHover = this.toggleHover.bind(this)
    }

    toggleHover() {
        this.setState({hover: !this.state.hover})
    }


    handleClose(event) {
        this.popup.setPosition(undefined);
        event.target.blur();
        return false;
    }

    openModal(){
        this.setState({visible: true})
    }

    closeModal(){
        this.setState({visible: false})
        this.setState({download_loading: false})
    }

    async onDownload(values) {
        
        this.setState({'download_loading': true })

        let datasetInfo = this.props.getQueryData()

        console.log("datasetInfo sddsd")
        console.log(datasetInfo)

        let base_url = process.env.REACT_APP_API_URL        

        const response = await fetch(`${base_url}/query_views/download?data=${JSON.stringify(datasetInfo)}&fileName=${encodeURIComponent(values.fileName)}`, 
            {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + this.props.access_token
                }
            }).then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement('a');
                a.href = url;
                a.download = values.fileName + '.zip';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => console.error('Error:', error));

        this.setState({'download_loading': false })
        
        this.closeModal()
    }

    render() {
        let rootStyle = this.props.style;

        if (rootStyle === undefined) {
            rootStyle = {
                ...rootStyle,
                position: 'absolute',
                top: '163px',
                left: '7px',
                padding: '1px 4px 1px 4px',
                borderRadius: '6px',
                backgroundColor: this.state.hover ? 'rgba(0, 60, 136, .8)' : 'rgba(0, 60, 136, .5)',
                border: '3px solid rgba(255, 255, 255, 0.72)',
            };
        }
        
        return (
            <div>
                <div style={rootStyle} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                    <Tooltip title={'Download'} placement="right" >
                        <DownloadOutlined onClick={this.openModal} style={{color: 'white'}}/>
                    </Tooltip>
                </div>
                <Modal title="Download Data"
                    open={this.state.visible}
                    onCancel={this.closeModal}
                    destroyOnClose={true}
                    // onOk={this.onDownload}
                    confirmLoading={this.state.download_loading}
                    footer={
                        this.state.download_loading
                        ?
                            <Button form="download_data" type="primary" htmlType="submit" disabled>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin/>} /> 
                                <span style={{paddingLeft: 10}}>Download</span>
                            </Button>
                        :                         
                        <Button form="download_data" type="primary" htmlType="submit">
                            Download
                        </Button>
                    }
                    width={700}
                    bodyStyle={{
                        maxHeight: 350
                    }}>
                <div>
                    <Form
                        name="download_data"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        layout="vertical"
                        autoComplete="off"
                        onFinish={(values) => this.onDownload(values)}                        
                        scrollToFirstError="true">
                        <Form.Item
                            style={{marginBottom: '4px'}}
                            label="File Name of Download"
                            name="fileName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your preferred file name',
                                },
                            ]}
                            >
                            <Input />
                        </Form.Item>
                        <div>
                            <span style={{fontSize: "10px"}}>* Do not add an extention to your file name</span>
                        </div>
                        <div style={{marginTop: '10px'}}>
                            <span>Reminder: This is a prototype and data available here is only provided for the purpose of gathering user feedback. Please acknowledge that you understand and will not use this data.</span>
                        </div>
                        <Form.Item
                            style={{marginTop: 20}}
                            name='reminder'
                            rules={[ 
                                {
                                required: true,
                                message: 'Please check the box stating you understand the data is only for user feedback use',
                            }
                            ]}
                            valuePropName="checked"
                        >
                            <Checkbox>
                                    I understand
                            </Checkbox>
                        </Form.Item>
                       
                        {/* <Form.Item style={{textAlign: 'center'}}>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item> */}
                    </Form>
                </div>
            </Modal>
            </div>
        );
    }   
}


export default ForestDataMapDownload;