import {DragAndDrop} from "ol/interaction";
import {GeoJSON, KML} from "ol/format";
import {getMainAttributes} from "./utils";
import {Vector as VectorSource} from "ol/source";
import {Vector as VectorLayer} from "ol/layer";
import {Fill, Stroke, Style, Text} from "ol/style";


const DragDropSupport = (props) => {

    const DBConfig = {
        name: "WFR_DB",
        version: 1,
        objectStoresMeta: [
            {
                store: "data",
                storeConfig: { keyPath: "id", autoIncrement: true },
                storeSchema: [
                    { name: "filename", keypath: "filename", options: { unique: false } },
                    { name: "definition", keypath: "definition", options: { unique: false } },
                ],
            },
        ],
    };


    const request = indexedDB.open("WFR_DB", 1);
    var db = null;
    request.onsuccess = function (evt) {
        db = request.result;
    };

    request.onerror = function (evt) {
        console.log("IndexedDB error: " + evt.target.errorCode);
    };

    request.onupgradeneeded = function (evt) {
        console.log("onupgradeneeded");
        let objectStore = evt.currentTarget.result.createObjectStore("data",
            { keyPath: "id", autoIncrement: true });
        objectStore.createIndex("filename", "filename", { unique: true });
        objectStore.createIndex("definition", "definition", { unique: false });
    };


    let dragAndDropInteraction = new DragAndDrop({
        formatConstructors: [GeoJSON, KML]
    });

    props.map.addInteraction(dragAndDropInteraction);

    const theMap = props.map;
    dragAndDropInteraction.on('addfeatures', function (event) {

        let file_name = event.file.name;
        let main_attrs = getMainAttributes(event.features);

        // save into indexedDB to keep it
        var transaction = db.transaction("data", "readwrite");
        var objectStore = transaction.objectStore("data");
        objectStore.add({
            filename: file_name,
            definition: JSON.stringify(event.features)
        });

        var vectorSource = new VectorSource({
            features: event.features
        });

        var vectorLayer = new VectorLayer({
            source: vectorSource,
            zIndex: 100,
            opacity: 0.3,
            style: (feature) => {
                return new Style({
                    stroke: new Stroke({
                        color: '#1A7CFA', //'#088FFA',
                        width: 1,
                    }),
                    fill: new Fill({
                        color: 'rgba(211, 211, 211, 0.1)',
                    }),
                    text: new Text({
                        font: '12px Calibri,sans-serif',
                        fill: new Fill({
                            color: '#000'
                        }),
                        // text: feature.get('name') ? feature.get('name') : feature.get('NAME'),
                        text: main_attrs.length ===1 ? feature.get(main_attrs[0]) : '',
                        stroke: new Stroke({
                            color: '#fff',
                            width: 3
                        })
                    })
                })
            }
        });
        vectorLayer.set("name", file_name);
        theMap.addLayer(vectorLayer);
        theMap.getView().fit(vectorSource.getExtent(), {padding: [50, 50, 50, 50]});

        // notify the parent component
        props.addLayerByUser(vectorLayer);

    })
}

export default DragDropSupport;
