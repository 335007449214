import React from 'react';
import {FilterOutlined} from '@ant-design/icons';
import {Modal, Typography, Checkbox, notification, Spin} from "antd";
import { CheckCircleOutlined, StopOutlined, LoadingOutlined} from '@ant-design/icons';

import 'antd/dist/antd.css';
import './query.css'
import Button from "antd/es/button";
import { Tooltip } from 'antd';


import GeoJSON from 'ol/format/GeoJSON.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Vector as VectorLayer} from 'ol/layer.js';
import {Stroke, Style} from 'ol/style.js';
import config from './config';
import {Circle as CircleStyle, Fill} from 'ol/style.js';
import WorkspaceContext from '../WorkspaceContext';
import OlLayerTile from 'ol/layer/Tile';
import OlSourceTileWMS from 'ol/source/TileWMS';
import {getSLDForLayer} from "./utils";
import { toInteger } from 'lodash';

const {Text} = Typography;
const image = new CircleStyle({
  radius: 5,
  fill: null,
  stroke: new Stroke({color: 'red', width: 1}),
});

const styles = {
  'Point': new Style({
    image: image,
  }),
  'LineString': new Style({
    stroke: new Stroke({
      color: 'rgba(0, 0, 240, 0.8)',
      width: 1,
    }),
  }),
  'MultiLineString': new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  'MultiPoint': new Style({
    image: image,
  }),
  'MultiPolygon': new Style({
    stroke: new Stroke({
      color: 'rgba(0, 0, 240, 0.8)',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 240, 0.8)',
    }),
  }),
  'Polygon': new Style({
    stroke: new Stroke({
      color: 'rgba(0, 0, 240, 0.8)',
      lineDash: [4],
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 240, 0.8)',
    }),
  }),
  'GeometryCollection': new Style({
    stroke: new Stroke({
      color: 'magenta',
      width: 2,
    }),
    fill: new Fill({
      color: 'magenta',
    }),
    image: new CircleStyle({
      radius: 10,
      fill: null,
      stroke: new Stroke({
        color: 'magenta',
      }),
    }),
  }),
  'Circle': new Style({
    stroke: new Stroke({
      color: 'red',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 240, 0.8)',
    }),
  }),
};

const styleFunction = function (feature) {
  return styles[feature.getGeometry().getType()];
  };

class ForestDataMapQueryTool extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            visible: false,
            is_intersect: false,
            query_loading: false
        };
        this.toggleHover = this.toggleHover.bind(this);
        this.showGUI = this.showGUI.bind(this);
        this.hideGUI = this.hideGUI.bind(this);
        this.onQuery = this.onQuery.bind(this);
        this.onReset = this.onReset.bind(this);
        this.getConfigLabels = this.getConfigLabels.bind(this);
        this.openNotification = this.openNotification.bind(this);
    }

    toggleHover() {
        this.setState({hover: !this.state.hover})
    }

    showGUI() {
        this.setState({
            visible: true
        });
    }

    hideGUI() {
        this.setState({
            visible: false
        })
    }

    onReset() {
    }

    getConfigLabels(){
      let allConfigNames = {}

      let rrk_datasets = config["Regional Resource Kit"]['Southern California']
      let its_datasets = config["Interagency Tracking System"]

      rrk_datasets.forEach(rrk => {
        allConfigNames[rrk.layer] = rrk.name
      })

      its_datasets.forEach(its => {
        allConfigNames[its.layer] = its.name
      })
       
      return allConfigNames
    }
    
    handleCheckboxChange(event){
        this.setState({is_intersect: event.target.checked})
    }

    openNotification(placement, message) {
      notification.info({
          message: message,
          icon: <StopOutlined />,
          style: {color: 'red'},
          placement,
      });
    };

    async onQuery() {

      //start spinner
      this.setState({'query_loading': true})

      let datasetInfo = this.props.getQueryData()

      console.log("datasetInfo sdkfas")
      console.log(datasetInfo)

      console.log(Object.keys(datasetInfo).length)

      if(Object.keys(datasetInfo).length < 2){
          this.setState({'query_loading': false })
          this.openNotification('top', "More than one datasource needs to be selected to intersect.")
      } else {

      let base_url = process.env.REACT_APP_API_URL

      const response = await fetch(`${base_url}/query_views/get_query?data=${JSON.stringify(datasetInfo)}`, 
          {
              method: "GET",
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + this.props.access_token
              },
          })
          .then((response) => response.json())
          .then((responseData) => {
              return responseData;
          })
          .catch(error => console.warn(error));


      console.log(response)

      if(response !== undefined && !('status' in response))
      {
        let datasetLabels = this.getConfigLabels()

        console.log("datasetLabels")
        console.log(datasetLabels)
        
        let allQueryLayers = this.props.queryLayers
        let allqueryLayerInfo = this.props.queryLayerInfo
        let allCheckedQueries = this.props.checkedQuerysets

        console.log(this.props)
        console.log(allQueryLayers)
        console.log(allqueryLayerInfo)
        console.log(allCheckedQueries)

        let features = response['allFeatures']
        let datasetNames = response['datasetNames']

        console.log("features")
        console.log(features)
        console.log('datasetnames')
        console.log(datasetNames)
        
        let queryResource = new VectorSource({
          features: new GeoJSON().readFeatures(features),
        }) 
        
        let queryResultLayer = new VectorLayer({
          source: queryResource,
          style: styleFunction
        })
        
        console.log(queryResultLayer)
        console.log(queryResultLayer['ol_uid'])
        
        let ol_uid = toInteger(queryResultLayer["ol_uid"])
        let layerName = "Intersection"
        let layerId = "filter: Intersection" + String(ol_uid -1)      
        let tooltipList = []

        datasetNames.forEach(e => {
          if(e in datasetLabels){
            tooltipList.push(datasetLabels[e])
          } else {
            tooltipList.push(e)
          }
        })

        let tooltip = tooltipList.join(', ')
        
        queryResultLayer.setProperties({
          layerId: layerId   
        })
        
        let item = {
          "color": 'rgba(0, 0, 240, 0.8)',
          "color_end" : "#160FFD",
          "layerId" : layerId,
          "name" : layerName,
          'tooltip': tooltip,
          'min': 0,
          'max': 1,
          "color_start" : "#FFD1FB",
          "type" : "vector",
          "url" : 'filter'
        }
        
        let dataset = {
          folder: "/Filtered By Intersection",
          mapping: [item],              
          visible: true
        }
        
        console.log('how bount here')
        
        allQueryLayers.push(queryResultLayer)
        allqueryLayerInfo.push(dataset)
        allCheckedQueries[layerId] = true
        
        console.log('here?')
        this.props.map.addLayer(queryResultLayer)
        console.log('final')
        
        this.props.setQueryLayers(allQueryLayers);
        this.props.setQueryLayerInfo(allqueryLayerInfo)
        this.props.setCheckedQuerysets(allCheckedQueries)
      
        // create layers from new filtered dataset
        // for (const [key, value] of Object.entries(datasetInfo)) {
        // let response_key = key.split(':')[1].toLowerCase()
          // let name_key = key.split('/').slice(-1)[0]
          
          // let [fieldName, fieldType, min, max] = value
          //     console.log('a;ldsfjakjsdflk;ajsdf')
          
          //     console.log(response_key)
          
          //     let features = response[response_key]
          //     let queryResource = new VectorSource({
            //       features: new GeoJSON().readFeatures(features, {
              //         featureProjection: "EPSG:4326"
              //       }),
              //     }) 
              
              //     console.log('get here??')
              //     let queryResultLayer = new VectorLayer({
                //       source: queryResource,
                //       style: styleFunction
                //     })
                
                //     let ol_uid = queryResultLayer["ol_uid"]
                //     let layerName = datasetLabels[key] + ' Filtered (' + String(min) + ' to ' + String(max) + ')'
                //     let layerId = "filter:" + name_key + '_filter_' + String(min) + '_' + String(max)  + '_' + ol_uid         
                
                //     queryResultLayer.setProperties({
                  //       layerId: layerId   
                  //     } )
                  
                  //     let item = {
                    //       "color": 'rgba(0, 0, 240, 0.8)',
                    //       "color_end" : "#160FFD",
                    //       "layerId" : layerId,
                    //       "name" : layerName,
                    //       'min': min,
                    //       'max': max,
                    //       "color_start" : "#FFD1FB",
                    //       "type" : "vector",
                    //       "url" : 'filter'
                    //     }
                    
                    //     let dataset = {
                      //       folder: "/Filtered By Intersection",
                      //       mapping: [item],              
                      //       visible: true
                      //     }
                      
                      //     console.log('how bount here')
                      
                      //     allQueryLayers.push(queryResultLayer)
                      //     allqueryLayerInfo.push(dataset)
                      //     allCheckedQueries[layerId] = true
                      
                      //     console.log('here?')
                      //     this.props.map.addLayer(queryResultLayer)
                      //     console.log('final')
                      // // }
                      
                      // this.props.setQueryLayers(allQueryLayers);
                      // this.props.setQueryLayerInfo(allqueryLayerInfo)
                      // this.props.setCheckedQuerysets(allCheckedQueries)

        this.setState({'query_loading': false})
        this.hideGUI()

      } else {
        this.openNotification('top', response['message'])
        document.getElementById("map").classList.remove('spinner');
      }
    }

  }


  render() {
      let rootStyle = this.props.style;
      if (rootStyle === undefined) {
          rootStyle = {
              position: 'absolute',
              top: '130px',
              left: '7px',
              padding: '1px 4px 1px 4px',
              borderRadius: '6px',
              backgroundColor: this.state.hover ? 'rgba(0, 60, 136, .8)' : 'rgba(0, 60, 136, .5)',
              border: '3px solid rgba(255, 255, 255, 0.72)',
          };
      }

      return (
          <div>
              <div style={rootStyle} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                <Tooltip title={'Create Intersection Layer'} placement="right">
                  <FilterOutlined onClick={this.showGUI} style={{color: 'white'}} />
                </Tooltip>
              </div>

              <Modal title="Create Intersection"
                      open={this.state.visible}
                      onOk={this.onQuery}
                      onCancel={this.hideGUI}
                      destroyOnClose={true}
                      confirmLoading={this.state.query_loading}
                      // width={700}
                      // bodyStyle={{
                      //     maxHeight: 350
                      // }}
              >
                  <div>
                      {/* <Checkbox
                          checked={this.state.is_intersect}
                          onChange={event => this.handleCheckboxChange(event)}>
                          <Text strong
                                style={{fontSize: 13}}>Filter By Intersection</Text>
                      </Checkbox> */}
                      <div>
                        An intersection layer will calculate all polygons that intersect all selected data sources. Filtering to any 
                        selected data sources will be applied. 
                      </div>
                      <div style={{marginTop: 15}}>
                        Are you sure you want to create an intersection layer?
                      </div>
                  </div>
              </Modal>

          </div>
      );
  }

}

export default ForestDataMapQueryTool;
