import React from 'react';
import {useState, useEffect, useContext} from "react";
import {FilterOutlined} from '@ant-design/icons';
import {Modal, Typography, Alert, Avatar, Form, Input, Button, Dropdown, List, Tooltip } from "antd";
import 'antd/dist/antd.css';
import './query.css'
import { Space, Table, Tag } from 'antd';
import {LoginOutlined, ImportOutlined, QuestionCircleOutlined, AppstoreOutlined, DeleteOutlined} from '@ant-design/icons';
import { UserContext } from '../UserContext';
import WorkspaceContext from '../WorkspaceContext';

const {Text} = Typography;
const { Column } = Table;
const syncfetch = require('sync-fetch')


//create table script
//CREATE TABLE "fdh".useraccounts
// (
// 	userid serial PRIMARY KEY,
// 	username VARCHAR,
// 	"password" VARCHAR
// )

class ForestDataMapWorkspaces extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            visible : false,
            delete_visible: false,
            selectedWorkspaceId: null,
            workspaceList: []
        };

        this.setUserIdHere = this.setUserIdHere.bind(this)
        this.toggleHover = this.toggleHover.bind(this);
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.showModal.bind(this)
        this.populateWorkspaceTable = this.populateWorkspaceTable.bind(this)
        this.loadWorkspace = this.loadWorkspace.bind(this);
    }

    static contextType = WorkspaceContext

    toggleHover() {
        this.setState({hover: !this.state.hover})
    }

    showModal() {
        this.setState({visible: true})
    }

    hideModal() {
        this.setState({visible: false})
    }

    showDeleteModal(record) {
        this.setState({delete_visible: true})
        this.setState({'selectedWorkspaceId': record.workspace_id})
        this.setState({'selectedWorkspaceName': record.workspace_name})
    }

    hideDeleteModal() {
        this.setState({delete_visible: false})
    }

    onFinishFailed = (errorInfo) => {
       this.setState({error: true})
    };

    setUserIdHere(userId){
        this.setState({loginUser: userId})
    }

    async populateWorkspaceTable(props)
    {
        console.log('did it get here?')
        console.log(props)
        
        let base_url = process.env.REACT_APP_API_URL

        const response = await fetch(`${base_url}/workspace/read_workspaces_by_user`, 
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + props.access_token
                }
            })
            .then((response) => response.json())
            .then((responseData) => {
                return responseData;
            })
            .catch(error => console.warn(error));

        this.setState({'workspaceList': JSON.parse(response['results']) })

        this.setState({"visible" : true})
    }
    
    async loadWorkspace(workspace){
        console.log(workspace)
        console.log('we made it')

        let results = JSON.parse(workspace['workspace_info'])

        let newSelectedDatasets = []
        results['selectedDatasets'].forEach(data => {
            let region = data['folder'].split('/')

            console.log(region)

            if (region[1] === 'Regional Resource Kit')
            {
                let category = data['category']
                let name = data['name']
                let url = `rrk_metadata/${region[2]}${category}/${name}_2.json`;
                let info = syncfetch(url).json()

                newSelectedDatasets.push(info)
            } 
            else if (region[1] === 'Interagency Tracking System'){
                console.log('its data')
                console.log(data)
                newSelectedDatasets.push(data)
            }else {
                console.log("boundaries data")
                console.log(data)
                newSelectedDatasets.push(data)
            }
        })

        this.context.setSelectedDatasets(newSelectedDatasets)

        let checkedDatasets = results['checkedDatasets']
        let valueRanges = results['valueRanges']

        this.context.setValueRanges(valueRanges)
        this.context.setCheckedDatasets(checkedDatasets)

        let base_url = process.env.REACT_APP_API_URL

        console.log("results")
        console.log(workspace)

        let workspaceId = workspace['workspace_id']

        const response = await fetch(`${base_url}/workspace/workspace_last_used/${workspaceId}`,
        {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + this.props.access_token
            }           
        })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {
            console.warn(error)
        });

        this.setState({"visible" : false})

    }

    async deleteWorkspace(workspaceId, props){

        console.log('workspaceId')
        console.log(workspaceId)

        let base_url = process.env.REACT_APP_API_URL

        const response = await fetch(`${base_url}/workspace/workspace/${workspaceId}`, 
        {
            method: "DELETE",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + props.access_token
            },
            body: JSON.stringify({
                'workspaceId' : workspaceId
            })
        })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));

        this.populateWorkspaceTable(props)

        this.hideDeleteModal()
    }

    render() {

        let iconStyle = {
            paddingRight: "5px"
        }

        console.log("this.state.workspaceList")
        console.log(this.state.workspaceList)

        let columns = [ 
            {
                title: 'Name',
                dataIndex: 'workspace_name',
                key: 'workspace_name',       
                sorter: (a, b) => a.workspace_name.localeCompare(b.workspace_name)
            },
            {
                title: () => <div><span style={{"marginRight": "10px"}}>Regional Resource Kit</span>
                {/* <Tooltip title="Bold titles are visible in the map. Values after titles are filtered metrics."><QuestionCircleOutlined /></Tooltip> */}
                </div>,
                dataIndex: 'workspace_info',
                key: 'workspace_info',
                render: (_, record ) => (
                    <div style={{"paddingLeft": "10px"}}>
                        <ul style={{"paddingLeft": "0px"}}>
                            {JSON.parse(record.workspace_info).selectedDatasets
                            .filter(d => d.folder.includes('Regional Resource Kit'))
                            .map((data) => {
                                return <li key={data.name}>
                                    {/* {data.name}{(data.isChecked ? ": " + String(data.values[0]) + ' to ' + String(data.values[1]) : "")} */}
                                    {data.name}
                                </li>
                                }
                            )}
                        </ul>
                    </div>
                    )
            },
            {
                title: () => <div><span style={{"marginRight": "10px"}}>Interagency Tracking System</span>
                {/* <Tooltip title="Bold titles are visible in the map. Values after titles are filtered metrics."><QuestionCircleOutlined /></Tooltip> */}
                </div>,
                dataIndex: 'all_datasets',
                key: 'all_datasets',
                render: (_, record ) => (
                    <div style={{"paddingLeft": "10px"}}>
                        <ul style={{"paddingLeft": "0px"}}>
                            {JSON.parse(record.workspace_info).selectedDatasets
                            .filter(d => d.folder.includes('Interagency Tracking System'))
                            .length > 0 ? 
                                <li key={'its'}>
                                    {/* {data.name}{(data.isChecked ? ": " + String(new Date(data.values[0] * 100000).toLocaleDateString().substring(0, 10)) 
                                        + ' to ' + String(new Date(data.values[1] * 100000).toLocaleDateString().substring(0, 10)) : "")} */}
                                    Interagency Tracking<br></br> System
                                </li>
                                : <div></div>
                            }                                      
                        </ul>
                    </div>
                    )
            },
            {
                title: 'Date Last Used',
                dataIndex: 'last_used_date_display',
                key: 'last_used_date_display',
                sorter: {
                    compare: (a, b) => a.last_used_date - b.last_used_date,
                    multiple: 1,
                },
                render: (_, record ) => (
                    <div style={{"paddingLeft": "10px"}}>
                        <ul style={{"paddingLeft": "0px"}}>
                            <div>{new Date(record.last_used_date).toLocaleDateString('en-US')}</div>
                        </ul>
                    </div>
                    )
            },
            {
                title: 'Actions',
                dataIndex: 'key',
                key: 'key',
                render: (text, record) => (
                    <div>
                        <div>
                            <Tooltip title="Load">
                                <Button onClick={()=> this.loadWorkspace(record)}  style={{marginBottom: '5px'}}
                                type="primary" size="small" icon={<ImportOutlined />}></Button>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title="Delete">
                                <Button onClick={()=> this.showDeleteModal(record)} type="danger" size="small" icon={<DeleteOutlined />}></Button>
                            </Tooltip>
                        </div>
                    </div>
                   ),
            }
        ]

        let rootStyle = this.props.style;
        if (rootStyle === undefined) {
            rootStyle = {
                position: 'absolute',
                top: '196px',
                left: '7px',
                padding: '1px 4px 1px 4px',
                borderRadius: '6px',
                backgroundColor: this.state.hover ? 'rgba(0, 60, 136, .8)' : 'rgba(0, 60, 136, .5)',
                border: '3px solid rgba(255, 255, 255, 0.72)',
            };
        }

       return ( 
        <div>
            <div>
                <a onClick={() => this.populateWorkspaceTable(this.props)} style={{color: 'black'}}>
                    <AppstoreOutlined style={{paddingRight: "5px"}}></AppstoreOutlined>My Hub
                </a>
            </div>
            <Modal title="My Hub"
                open={this.state.visible}
                onCancel={() => this.setState({"visible" : false})}
                footer={null}
                width={1100}
                style={
                    {
                        maxHeight: "300px"
                    }
                }
                >
                <div>
                    <h4>Saved Workspaces</h4>
                    <Table dataSource={this.state.workspaceList} columns={columns}
                        pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30']}}
                        >
                        <Column title='Workspace Name' dataIndex='workspace_name' key='workspace_name'/>
                        <Column title="Datasets" dataIndex="all_datasets" key="all_datasets"/>
                        <Column title="Filtered Datasets" dataIndex="value_ranges" key="value_ranges" />
                        <Column title="Date Last Used" dataIndex="last_used_date" key="last_used_date"
                            render={(date) => {
                                <Space></Space>
                            }} />
                        <Column title="Action" key='action'>
                        </Column>
                    </Table>
                </div>
            </Modal>
            <Modal 
                open={this.state.delete_visible}
                onCancel={() => this.setState({"delete_visible" : false, "selectedWorkspaceId" : null})}
                onOk={() => this.deleteWorkspace(this.state.selectedWorkspaceId, this.props)}
                okText="Delete"
                okType='danger'
                cancelText="Cancel"
            >
                <div>
                    Are you sure you want to delete {this.state.selectedWorkspaceName} workspace? 
                </div>
            </Modal>
        </div>
    )}

}

export default ForestDataMapWorkspaces;
