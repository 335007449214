import React, {useState, useEffect, useContext, useRef} from 'react';
import {Divider, Checkbox, Collapse, Space, Slider, Row, Col, Typography, Modal} from "antd";
import InfoCircleTwoTone from "@ant-design/icons/es/icons/InfoCircleTwoTone";
import 'antd/dist/antd.css';
import {getSLDForLayer} from './utils'
import MiniITSDashboard from './ITSDashboard/MiniITSDashboard'
import Button from "antd/es/button";
import WorkspaceContext from "../WorkspaceContext";
import {CloseOutlined, InfoCircleOutlined, LeftCircleFilled} from '@ant-design/icons';
import { Tooltip } from 'antd';
import UserDataReport from "../report/UserDataReport";
import WFSDataReport from "../report/WFSDataReport";

const {Panel} = Collapse;
const {Text} = Typography;


const ForestDataMapLayerTree = (props) => {

    const {selectedDatasets, setRegionReq, setDefaultKeys, setSelectedKeys, setSelectedDatasets} = useContext(WorkspaceContext);
    const [layers, setLayers] = useState(props.layers);
    // const [checkedDatasets, props.setCheckedDatasets] = useState(checkedDatasets);
    const [queryLayers, setQueryLayers] = useState(props.queryLayers)
    const [checkedValues, setCheckedValues] = useState({});
    const [opacities, setOpacities] = useState({});
    const [showMiniITSDashboard, setShowMiniITSDashboard] = useState(false);
    const [unitsForModal, setUnits] = useState(null);
    const [unitModalTitle, setUnitModalTitle] = useState(null);
    const [openUnitsModal, setOpenUnitsModal] = useState(false);

    const itsDashboardRef = useRef();

    useEffect(() => {
        let values = {};
        let ops = {};

        console.log("useEffect")

        console.log(props.checkedDatasets)
        console.log('layers layers')

        props.layers.forEach(e => {
            console.log(e.getSource().getParams())
        })

        for (var i = 0; i < props.layers.length; i++) {
            let layer = props.layers[i];
            let urls = layer.getSource().getUrls();
            let key = urls[0] + "/" + layer.getSource().getParams().LAYERS;
            values[key] = layer.getVisible();
            ops[key] = layer.getOpacity();
        }

        let newValueRanges = {...props.valueRanges};
        let changed = false;
        for (var j = 0; j < selectedDatasets.length; j++) {
            let item = selectedDatasets[j];
            let key = item.mapping[0].url + "/" + item.mapping[0].layer;
            let found = false;
            for (i = 0; i < props.layers.length; i++) {
                let layer = props.layers[i];
                if (item.mapping[0].url === layer.getSource().getUrls().toString() &&
                    item.mapping[0].layer === layer.getSource().getParams().LAYERS.toString()) {
                    found = true;
                    break;
                }
            }
            if (!found && item.folder.startsWith('/Regional Resource Kit')) {
                console.log('key not found')
                console.log(key)

                values[key] = true;
                ops[key] = 0.7;
            }

            if (!newValueRanges[key]) {
                newValueRanges[key] = [item.mapping[0].min, item.mapping[0].max];
                changed = true;
            }
        }

        // below loops are to check for proper settings when loading workspaces 

        // if new dataset added, check with checked datasets to make sure the checked settings are correct
        Object.keys(props.checkedDatasets).forEach(key => {
            values[key] = props.checkedDatasets[key]
        })

        // make sure checked datasets that are checked are visible
        Object.keys(values).filter(function (key) { return values[key] }).forEach(key => {
            for (i = 0; i < props.layers.length; i++) {
                let layer = props.layers[i];
                if (key === (layer.getSource().getUrls().toString() + '/' + layer.getSource().getParams().LAYERS.toString()))
                {
                    layer.setVisible(values[key])                    
                }
            }
        })

        console.log("values asdfasdf")
        console.log(values)

        console.log("props.categories walla")
        console.log(props.categories)

        props.setCheckedDatasets(values);
        setOpacities(ops);
        if (changed) {
            props.setValueRanges(newValueRanges);
        }
        // console.log('layers being saved')
        // props.layers.forEach(e => {
        //     console.log(e.getSource().getParams())
        // })
        setLayers(props.layers);
    }, [selectedDatasets, props.valueRanges, props.layer]);


    useEffect(() => {
        props.layers.forEach(layer => {
            if (layer.getVisible()){
                let key =layer.getSource().getUrls().toString() + '/' + layer.getSource().getParams().LAYERS.toString()
                let item = selectedDatasets.filter(function(item) {
                    return item.mapping[0].url + "/" + item.mapping[0].layer === key
                })[0]

                if(item != undefined){
                    console.log('here item undefined')
                    onValueRangeChange(props.valueRanges[key], item.mapping[0])
                }
            }
        })
    }, [props.layer, selectedDatasets])
   
    useEffect(() => {
        let checkedQueries = {}
        props.queryLayers.forEach(layer => {
            let isVisible = layer.getVisible()
            checkedQueries[layer.getProperties()['layerId']] = isVisible
        })

        for(var i = 0; i < props.queryLayerInfo.length; i++) {
            let layerId = props.queryLayerInfo[i].mapping[0].layerId
            let found = false
            props.queryLayers.forEach(layer => {
                if (layer.getProperties()['layerId'] === layerId){
                    found = true;
                    return;
                }
            })

            if(!found){
                let layerInfo = props.queryLayerInfo
                layerInfo.splice(i, 1)
                props.setQueryLayerInfo(layerInfo)
            }
        }

        props.setCheckedQuerysets(checkedQueries)

    }, [props.queryLayers]);


    const handleCheckboxChange = (event, item) => {
        let layer = getLayer(item);
        if (layer) {          
            layer.setVisible(event.target.checked);
            let newCheckedValues = {...props.checkedDatasets};
            newCheckedValues[item.url + "/" + item.layer] = event.target.checked;
            // setCheckedDatasets(newCheckedValues);
            props.setCheckedDatasets(newCheckedValues)
            layer.getSource().refresh();
            setTimeout(() => layer.getSource().refresh(), 1000);
        }
    }

    function handleITSCheckboxChange(event) {
        let itsDatasets = selectedDatasets.filter(item => item.folder === '/Interagency Tracking System')

        for (let i = 0; i < itsDatasets.length; i++) {
            let item = itsDatasets[i]
            let layer = getLayer(item.mapping[0])
            layer.setVisible(event.target.checked)
            layer.getSource().refresh();
            // setTimeout(() => layer.getSource().refresh(), 1000);
        }

        // need to change all checked values at once and save once to prevent race conditions with updating
        let newCheckedValues = {...props.checkedDatasets};

        itsDatasets.forEach(item => {
            newCheckedValues[item.mapping[0].url + "/" + item.mapping[0].layer] = event.target.checked;
        })

        props.setCheckedDatasets(newCheckedValues)
       
    }


    const handleQueryCheckboxChange = (event, item) => {
        console.log("item handleQueryCheckboxChange")
        console.log(item)
        let layer = getQueryLayer(item);
        if (layer) {
            console.log('layer handle checkbox')
            console.log(layer)
            console.log(event.target.checked)
            layer.setVisible(event.target.checked);
            let newCheckedQuerysets = {...props.checkedQuerysets};
            newCheckedQuerysets[item.layerId] = event.target.checked;
            props.setCheckedQuerysets(newCheckedQuerysets);
        }
    }

    const onSliderChange = (value, item) => {
        let layer = getLayer(item);
        if (layer) {
            layer.setOpacity(value);
            let newOps = {...opacities};
            newOps[item.url + "/" + item.layer] = value;
            setOpacities(newOps);
        }
    }

    const onITSSliderChange = (value) => {
        let itsDatasets = selectedDatasets.filter(item => item.folder === '/Interagency Tracking System')

        itsDatasets.forEach((item, i) => {
            onSliderChange(value, item.mapping[0])
        })
    }


    const onValueRangeChange = (value, item) => {
        let layer = getLayer(item);
        console.log('on value range change here')
        console.log(value)
        if (layer) {
            let newValueRanges = {...props.valueRanges};
            newValueRanges[item.url + "/" + item.layer] = value;
            props.setValueRanges(newValueRanges);
            console.log(newValueRanges)
            if (item.type === 'raster') {
                console.log('is raster')
                console.log(item)
                console.log(value)
                let sld = getSLDForLayer(
                    item.layer,
                    item.start_color,
                    item.end_color,
                    item.max,
                    item.min,
                    value[1],
                    value[0]);
                layer.getSource().updateParams({'STYLES': undefined});
                layer.getSource().updateParams({'SLD_BODY': sld});

            } else if (item.type === 'vector') {
                try {
                    console.log("layer here")
                    let itsDatasets = selectedDatasets.filter(item => item.folder === '/Interagency Tracking System')
                    let keys = []
                    for (let i = 0; i < itsDatasets.length; i++) {
                        let item = itsDatasets[i]
                        let layer = getLayer(item.mapping[0])

                        console.log(layer)
                        console.log(layer.getSource())

                        keys.push(item.mapping[0].url + "/" + item.mapping[0].layer)

                        let cql = `activity_end >= '${value[0] * 100000}' `;
                        cql += ` AND activity_end <= '${value[1] * 100000}' `;
                        cql += ` AND primary_ownership_group is not null`;
                        cql += ` AND agency is not null`;
                        cql += ` AND activity_cat is not null`;
                        // let cql = `activity_end_date >= '${new Date(value[0] * 100000).toISOString().substring(0, 10)}' `;
                        // cql += ` AND activity_end_date <= '${new Date(value[1] * 100000).toISOString().substring(0, 10)}' `;
                        // cql += ` AND NOT activity_cat = 'WATSHD_IMPRV'`
                        // cql += ` AND counts_to_mas = 'YES' `;
                        // cql += ` AND activity_quantity is not null`
                        // cql += ` AND (agency is not null)`

                        console.log('cql here here ')
                        console.log(cql)

                        console.log("showMiniITSDashboard")
                        console.log(showMiniITSDashboard)

                        if (showMiniITSDashboard === true) {
                            // if (categories.length > 0){
                            //     let cat_cql = categories.map(item => ` activity_cat = '${getCategoryValue(item)}' `).join(' OR ');
                            //     cql += ` AND (${cat_cql}) `
                            // } else {
                            //     cql += ` AND (activity_cat == '' or activity_cat is null) `
                            // }
                            // if (owners.length > 0){
                            //     let owner_cql = owners.map(item => ` primary_ownership_group = '${getOwnerValue(item)}' `).join(' OR ');
                            //     cql += ` AND (${owner_cql}) `
                            // }else{
                            //     cql += ` AND (primary_ownership_group == '' or primary_ownership_group is null) `
                            // }

                            // if (agencies.length > 0){
                            //     let agency_cql = agencies.map(item => ` agency = '${getAgencyValue(item)}' `).join(' OR ');
                            //     cql += ` AND (${agency_cql}) `
                            // } else {
                            //     cql += ` AND (agency == '' or agency is null) `
                            // }

                            console.log('look here for filters')
                            console.log('props.categories')
                            console.log(props.categories)

                            console.log('props.owners')
                            console.log(props.owners)

                            console.log('props.agencies')
                            console.log(props.agencies)

                            let cat_cql = props.categories.map(item => {
                                if (item === 'Not Reported') {
                                    return ` activity_cat is ${getCategoryValue(item)} `
                                }else{
                                    return ` activity_cat = '${getCategoryValue(item)}' `
                                }
                            }).join(' OR ');
                            cql += ` AND (${cat_cql}) `

                            console.log("props.owners")
                            console.log(props.owners)
                            let owner_cql = props.owners.map(item => 
                                {
                                    if (item === 'Not Reported') {
                                        return ` primary_ownership_group is ${getOwnerValue(item)} `
                                    }else{
                                        return ` primary_ownership_group = '${getOwnerValue(item)}' `
                                    }
                                }).join(' OR ');
                            cql += ` AND (${owner_cql}) `

                            let agency_cql = props.agencies.map(item =>
                                {
                                    if (item === 'Not Reported') {
                                        return ` agency is ${getAgencyValue(item)} `
                                    }else{
                                        return ` agency = '${getAgencyValue(item)}' `
                                    }
                                }).join(' OR ');
                            cql += ` AND (${agency_cql}) `
                        }

                        layer.getSource().updateParams({
                            'cql_filter': cql
                        });

                        console.log("cql_filter")
                        console.log(cql)
                    }

                    let newValueRanges = {...props.valueRanges};

                    keys.forEach(e => {
                        newValueRanges[e] = value;
                    })

                    props.setValueRanges(newValueRanges);
                } catch (e) {
                    //console.log(e);
                }

            }
        }
    }

    const getLayer = (item) => { 
        if (props.layers) {
            for (var i = 0; i < props.layers.length; i++) {
                var layer = props.layers[i];
                let urls = layer.getSource().getUrls();
                if ((urls[0] + "/" + layer.getSource().getParams().LAYERS) === (item.url + "/" + item.layer)) {
                    return layer;
                }
            }
        }
        return null;
    }


    const getQueryLayer = (item) => {
        let layer_found = null
        if (props.map.getLayers() && item.layerId !== undefined ) {
            props.map.getLayers().forEach(layer => {
                if(layer_found == null){
                    if (layer.getProperties){
                        if (item.layerId === layer.getProperties()['layerId']) {
                            layer_found = layer;
                        }
                    }
                }
            })
        }

        return layer_found;
    }

    const date_formatter = (value) => {
        return `${new Date(value * 100000).toISOString().substring(0, 10)}`;
    }

    const [openModal, setOpenModal] = useState(false);
    const [modalTitle,] = useState('Data Report');

    const [selectedUserLayer, setSelectedUserLayer] = useState();
    const [userLayerChecked, setUserLayerChecked] = useState(props.layersByUser.map(item => true));

    const [selectedBoundaryDataset, setSelectedBoundaryDataset] = useState();

    useEffect(() => {
        setUserLayerChecked(props.layersByUser.map(layer => layer.getVisible()));
    }, [props.layersByUser]);

    const hideModal = () => {
        setOpenModal(false);
    };

    const getSelectedKeys = (split_path) => {

        let selectedKeys = []
        let prevItem = ''
        split_path.forEach(e => {
            let key = prevItem + '/' + e
            selectedKeys.push(key)
            prevItem = key
        })

        return selectedKeys
    }

    const onInfo = (item) => {
        props.setDisplay("data");
        console.log("item asdfasdf")
        console.log(item)
        setRegionReq(item.folder.split('/')[2]);
        let category = item.category + "/" + item.title;

        let split_path = category.split('/').splice(1)
        let selected_keys = getSelectedKeys(split_path)

        console.log(selected_keys)

        setDefaultKeys(selected_keys)
        setSelectedKeys(selected_keys)        
        setTimeout(() => props.setCategory(category.split('/')), 300);
    }

    // move the scroll bar to the top for opening a popup by clicking on the map
    useEffect(() => {
        let div = document.getElementById('info');
        if (div) {
            setTimeout(() => {
                div.scrollTop = 0;
            }, 500);
        }
    }, [modalTitle]);

    const isITSLayersActive = () => {
        for (let i = 0; i < selectedDatasets.length; i++) {
            let dataset = selectedDatasets[i];
            if (dataset.folder === '/Interagency Tracking System' &&
            props.checkedDatasets[dataset.mapping[0].url + "/" + dataset.mapping[0].layer]) {
                return true;
            }
        }
        return false;
    }

    const getValidValueRanges = () => {
        let result = {};
        for (let i = 0; i < selectedDatasets.length; i++) {
            let dataset = selectedDatasets[i];
            let key = dataset.mapping[0].url + "/" + dataset.mapping[0].layer;
            if (dataset.folder === '/Interagency Tracking System' && props.checkedDatasets[key]) {
                result[key] =props.valueRanges[key];
            }
        }
        return result;
    }

    const getCheckedDatasetsForReport = () => {
        let result = [];
        for (let i = 0; i < selectedDatasets.length; i++) {
            let dataset = selectedDatasets[i];
            let key = dataset.mapping[0].url + "/" + dataset.mapping[0].layer;
            if (props.checkedDatasets[key]) {
                if (dataset.folder === '/Interagency Tracking System') {
                    // if (dataset.mapping[0].name === 'Million Acres Flat File Points') {
                        result.push(dataset);
                    // }
                } else if (dataset.folder !== '/Boundaries') {
                    result.push(dataset);
                }
            }
        }

        return result;
    }

    const getCheckedDatasets = () => {
        let result = [];
        for (let i = 0; i < selectedDatasets.length; i++) {
            let dataset = selectedDatasets[i];
            let key = dataset.mapping[0].url + "/" + dataset.mapping[0].layer;
            if (props.checkedDatasets[key]) {
                result.push(dataset);
            }
        }

        return result;
    }

    useEffect(() => {
        console.log('use effect categories')
        for (let i = 0; i < selectedDatasets.length; i++) {
            let dataset = selectedDatasets[i];
            let key = dataset.mapping[0].url + "/" + dataset.mapping[0].layer;
            // console.log(key)
            // console.log(dataset.folder)
            // console.log(props.checkedDatasets[key])
            if (dataset.folder === '/Interagency Tracking System' && props.checkedDatasets[key]) {
                onValueRangeChange(props.valueRanges[key], dataset.mapping[0])
            }
        }
        
    }, [props.categories, props.owners, props.agencies, showMiniITSDashboard]);


    const getCategoryValue = (category) => {
        if (category === "Grazing") {
            return "GRAZING";
        } else if (category === "Not Defined") {
            return "NOT_DEFINED";
        } else if (category === "Sanitation & Salvage") {
            return "SANI_SALVG";
        } else if (category === "Mechanical and Hand Fuels Reduction") {
            return "MECH_HFR";
        } else if (category === "Timber Harvest") {
            return "TIMB_HARV";
        } else if (category === "Land Protection") {
            return "LAND_PROTEC";
        // } else if (category === "Watershed & Habitat Improvement") {
        //     return "WATSHD_IMPRV";
        } else if (category === "Beneficial Fire") {
            return "BENEFICIAL_FIRE";
        } else if (category === "Tree Planting") {
            return "TREE_PLNTING";
        } else if(category === 'Not Reported'){
            return null;
        }else {
            return null;
        }
    }


    const getOwnerValue = (owner) => {
        if (owner === "Federal") {
            return "FEDERAL";
        } else if (owner === "Local") {
            return "LOCAL";
        } else if (owner === "NGO") {
            return "NGO";
        } else if (owner === "Private Industry") {
            return "PRIVATE_INDUSTRY";
        } else if (owner === "Private Non-Industry") {
            return "PRIVATE_NON-INDUSTRY";
        } else if (owner === "State") {
            return "STATE";
        } else if (owner === "Tribal") {
            return "TRIBAL";
        } else if (owner === "Not Reported") {
            return null;
        }  else {
            return null;
        }
    }

    const getAgencyValue = (agency) => {
        if (agency === "CA Environmental Protection Agency") {
            return "CALEPA";
        } else if (agency === "CA State Transportation Agency") {
            return "CALSTA";
        } else if (agency === "CA Natural Resources Agency") {
            return "CNRA";
        } else if (agency === "Department of Defense") {
            return "DOD";
        } else if (agency === "Department of the Interior") {
            return "DOI";
        } else if (agency === "Department of Agriculture") {
            return "USDA";
        } else if (agency === "CA Air Resources Board"){
            return "CARB"        
        } else if (agency === 'Timber Companies') {
            return 'Timber Companies'
        } else if (agency === "Other") {
            return "OTHER";
        } else if (agency === 'Not Reported') {
            return null
        } else {
            return null;
        }
    }

    const removeDatasetFromWorkspace = (event, item) => {
        let keyToDelete = item.url + '/' + item.layer
        console.log("keyToDelete")
        console.log(keyToDelete)
        if (event){
            for (let i=0; i<selectedDatasets.length; i++) {
                let data = selectedDatasets[i].mapping[0]
                let datasetKey = data.url + '/' + data.layer
                console.log("datasetKey")
                console.log(datasetKey)
                if (keyToDelete === datasetKey) {
                    console.log('got in here')
                    let newSelectedDatasets = [...selectedDatasets];
                    console.log('newSelectedDatasets')
                    console.log(newSelectedDatasets)
                    newSelectedDatasets.splice(i, 1);
                    console.log('newSelectedDatasets')
                    console.log(newSelectedDatasets)
                    setSelectedDatasets(newSelectedDatasets);
                    break;
                }
            }
        }
    }

    const getRRKRegions = () => {
        let regionList = selectedDatasets
            .filter(item => item.folder.startsWith('/Regional Resource Kit'))
            .map(item => item.folder.split('/')[2]);
        let regions = [];
        regionList.forEach(region => {
            if (!regions.includes(region)) {
                regions.push(region);
            }
        });
        return regions.sort();
    }


    const removeQueryFromWorkspace = async (event, item) => {
        
        event.target.checked = false
        await handleQueryCheckboxChange(event, item.mapping[0])

        if (event){
            let keyToDelete = item.mapping[0].layerId

            console.log("selectedDatasets")
            console.log(selectedDatasets)

            for (let i=0; i<props.queryLayers.length; i++) {
                let datasetKey = props.queryLayers[i].getProperties()['layerId']
                if (keyToDelete === datasetKey) {
                    let queryLayers = [...props.queryLayers];
                    queryLayers.splice(i, 1);
                    await props.setQueryLayers(queryLayers);
                    break;
                }
            }

            let checkedQueries = {...props.checkedQuerysets}
            checkedQueries[keyToDelete] = false
            await props.setCheckedQuerysets(checkedQueries)

            let layer = getQueryLayer(item.mapping[0])
            console.log("layer for query")
            console.log(layer)
            console.log(layer.getSource())
            layer.setVisible(false);
            props.map.removeLayer(layer)

            for (let i=0; i<props.queryLayerInfo.length; i++) {
                // let data = props.queryLayerInfo[i].mapping[0]
                let datasetKey = props.queryLayerInfo[i].mapping[0].layerId
                if (keyToDelete === datasetKey) {
                    let queryLayerInfos = [...props.queryLayerInfo];
                    queryLayerInfos.splice(i, 1);
                    await props.setQueryLayerInfo(queryLayerInfos);
                    break;
                }
            }

        }
    }

    const openUnitModal = (units, title) => {
        setUnits(units)
        setUnitModalTitle(title)   
        setOpenUnitsModal(true)     
    }


    const isFilterActive = () => {
        return props.queryLayerInfo.length > 0 
    }

    let rootStyle = {
        position: 'absolute',
        type: "text",
        value: 'small',
        borderRadius: '6px'
    };



    return (

        <div style={{
            color: '#000000a6',
            align: 'center',
            height: 'calc(100vh - 45px)',
            backgroundColor: "#E5E4E2",
            width: "350px",
            position: "absolute",
            top: 0,
            right: 0,
            padding: '5px 1px 1px 4px',
            overflow: 'auto',
        }}>
            <Space direction="vertical" style={{marginBottom: '10px'}}>

            {/* ******************** Intersection ******************* */}
            {
                    isFilterActive() ? 
                        <Collapse collapsible="icon" defaultActiveKey={['1']}>
                            <Panel header="Intersection Layers" key="1"
                                style={{fontWeight: 'bold', backgroundColor: '#EEEDE3'}}>
                                {
                                    props.queryLayerInfo.map((item, i) => (
                                        item.mapping[0].type === 'vector' && item.mapping[0].layerId  ?
                                            <div key={'filter' + i} style={{fontWeight: 'normal'}}>
                                                <Row>
                                                    <Col span={21}>
                                                    <Checkbox
                                                        style={{
                                                            "--background-color": '#0014EC',
                                                            "--border-color": '#0014EC',
                                                        }}  
                                                     checked={props.checkedQuerysets[item.mapping[0].layerId]}
                                                        onChange={event => handleQueryCheckboxChange(event, item.mapping[0])}>
                                                        <Text strong
                                                        style={{fontSize: 13, paddingRight: 5}}>{item.mapping[0].name}</Text>
                                                        <Tooltip title={item.mapping[0].tooltip} placement='top'>
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                        
                                                    </Checkbox>
                                                    </Col>
                                                    <Col span={3}>
                                                        <div style={rootStyle}>
                                                            <Tooltip title={'Remove From \n Workspace'} placement="right" >
                                                                <Button icon={<CloseOutlined />} size={"small"} 
                                                                    onClick={event => removeQueryFromWorkspace(event, item)}></Button>
                                                            </Tooltip>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                
                                                {
                                                    i < props.queryLayerInfo.length - 1 ?
                                                        <Divider style={{
                                                            margin: '5px 0px 10px 0px',
                                                            borderTop: '1px solid lightgray'
                                                        }}/>
                                                        : null
                                                }
                                            </div> : null
                                    ))
                                            }
                            </Panel>
                        </Collapse>
                    : null
                }     

                {/* ******************** Regional Resource Kit ******************* */}

                <Collapse collapsible="header" defaultActiveKey={['1']}>
                    <Panel header="Regional Resource Kits" key="1"
                           style={{fontWeight: 'bold', width: 340, backgroundColor: '#EEEDE3'}}>
                           {/* style={{fontWeight: 'bold', width: 340, backgroundColor: '#B2BEB5'}}> */}
                        <div>                            
                            {
                                getRRKRegions().length === 0
                                ? <span>Go to the RRK Data Catalog to add data </span>
                                :
                                getRRKRegions().map((region, regionIndex) => (
                                    <div key={'rrk-region-' + region}>
                                        <div style={{
                                            width: '100%',
                                            fontWeight: 'bold',
                                            // backgroundColor: '#EEEDE2',
                                            marginTop: regionIndex === 0 ? -5 : 8,
                                            marginBottom: 10,
                                            paddingLeft: 25,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            borderBottom: '1px solid lightgray'
                                        }}>
                                            {region}
                                        </div>

                                        {
                                            selectedDatasets.filter(item => item.folder === '/Regional Resource Kit/' + region).map((item, i) => (
                                                item.mapping[0].type === 'raster' ?
                                                    <div key={'rrk_selected_' + i} style={{fontWeight: 'normal'}}>
                                                    <Row>
                                                        <Col span={21}>
                                                            <Checkbox 
                                                                style={{
                                                                    "--background-color": item.mapping[0].start_color,
                                                                    "--border-color":  item.mapping[0].end_color,
                                                                }}                                                        
                                                                checked={props.checkedDatasets[item.mapping[0].url + "/" + item.mapping[0].layer]}
                                                                onChange={event => handleCheckboxChange(event, item.mapping[0])}>
                                                                <Text strong
                                                                    style={{fontSize: 13}}>{item.mapping[0].name}</Text>
                                                            </Checkbox>
                                                        </Col>
                                                        <Col span={3}>
                                                            <div style={rootStyle}>
                                                                <Tooltip title={'Remove From \n Workspace'} placement="right" >
                                                                    <Button icon={<CloseOutlined />} size={"small"}
                                                                        onClick={event => removeDatasetFromWorkspace(event, item.mapping[0])}></Button>
                                                                </Tooltip>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                        <div style={{paddingLeft: '25px'}}>
                                                            <Row align="middle" style={{marginBottom: -6}}>
                                                                <Col span={10}>
                                                                    <Text style={{fontSize: 13}}>Opacity</Text>
                                                                </Col>
                                                                <Col span={14}>
                                                                    <Slider style={{width: '130px'}}
                                                                            min={0}
                                                                            max={1}
                                                                            step={0.01}
                                                                            value={opacities[item.mapping[0].url + "/" + item.mapping[0].layer]}
                                                                            onChange={value => onSliderChange(value, item.mapping[0])}
                                                                            disabled={!props.checkedDatasets[item.mapping[0].url + "/" + item.mapping[0].layer]}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row align="middle">
                                                                <Col span={10}>
                                                                    <Text style={{fontSize: 13, paddingRight: 5}}>Metric Values</Text>
                                                                    <InfoCircleTwoTone twoToneColor="green" onClick={() => onInfo(item)}/>
                                                                </Col>
                                                                <Col span={14}>
                                                                    <Slider range
                                                                            style={{width: '130px'}}
                                                                            trackStyle={
                                                                                props.checkedDatasets[item.mapping[0].url + "/" + item.mapping[0].layer] ?
                                                                                    {backgroundImage: 'linear-gradient(to right,' + item.mapping[0].start_color + ',' + item.mapping[0].end_color + ')'}
                                                                                    :
                                                                                    {}
                                                                            }
                                                                            handleStyle={{borderColor: 'gray'}}
                                                                            step={
                                                                                item.mapping[0].max - item.mapping[0].min < 1 ?
                                                                                    Number((Math.abs(item.mapping[0].max - item.mapping[0].min) / 20).toFixed(3))
                                                                                    :
                                                                                    // Number(item.mapping[0].max) === 1 && Number(item.mapping[0].min) === 0
                                                                                    // ? .1
                                                                                    // : 
                                                                                    // Number.isInteger(item.mapping[0].max) && Number.isInteger(item.mapping[0].min)
                                                                                    // ? 1
                                                                                    // : 
                                                                                    item.mapping[0].max - item.mapping[0].min < 10 ?
                                                                                        Number((Math.abs(item.mapping[0].max - item.mapping[0].min) / 130).toFixed(2))
                                                                                        :
                                                                                        1
                                                                            }
                                                                            max={item.mapping[0].max}
                                                                            min={item.mapping[0].min}
                                                                            value={
                                                                                props.valueRanges[item.mapping[0].url + "/" + item.mapping[0].layer]
                                                                            }
                                                                            onChange={value => onValueRangeChange(value, item.mapping[0])}
                                                                            disabled={!props.checkedDatasets[item.mapping[0].url + "/" + item.mapping[0].layer]}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Text style={{fontSize: 13, paddingRight: 15, color:'grey'}}>Units: {                                                                        
                                                                        item.data_units
                                                                        ? 
                                                                            item.data_units.length > 150 
                                                                            ? <span>
                                                                                <span style={{paddingLeft: 5}}><InfoCircleTwoTone twoToneColor="green" onClick={() => openUnitModal(item.data_units, item.mapping[0].name)}/></span>
                                                                            </span>
                                                                            : <span dangerouslySetInnerHTML={{__html: item.data_units}}></span>
                                                                            
                                                                        : <span>No Data Units Provided</span>
                                                                    }</Text>
                                                                </Col>
                                                            {/* <Col span={10}>
                                                                    <Text style={{fontSize: 13, float: 'right', paddingRight: 15, color:'grey'}}>Units</Text>
                                                                </Col>
                                                                <Col span={14}>
                                                                   <Text style={{fontSize: 13, paddingRight: 5, color:'grey'}}>{item.data_units}</Text>
                                                                </Col> */}
                                                            </Row>
                                                        </div>
                                                        {
                                                            i < selectedDatasets.filter(item => item.folder === '/Regional Resource Kit/' + region).length - 1 ?
                                                                <Divider style={{
                                                                    margin: '5px 0px 10px 0px',
                                                                    borderTop: '1px solid lightgray'
                                                                }}/> : null

                                                        }
                                                    </div> : null
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </Panel>
                </Collapse>

                {/* ******************** Interagency Tracking System ******************* */}

                <Collapse collapsible="icon" defaultActiveKey={['1']}>
                    <Panel header="Interagency Tracking System" key="1"
                           style={{fontWeight: 'bold', backgroundColor: '#EEEDE3'}}>
                        {
                            selectedDatasets.filter(item => item.folder === '/Interagency Tracking System').map((item, i) => (
                                item.mapping[0].type === 'vector' &&
                                item.folder === '/Interagency Tracking System' ?
                                <div key={'its_' + i} style={{fontWeight: 'normal', 'display': (i === 0 ? "block" : 'none')}}>
                                    <Row>
                                        <Col span={21}>
                                            <Checkbox id={'its_' + i + '_0'}                                               
                                                checked={props.checkedDatasets[item.mapping[0].url + "/" + item.mapping[0].layer]}
                                                onChange={event => handleITSCheckboxChange(event)}
                                                className='itsCheckbox'>
                                                <Text strong
                                                    style={{fontSize: 13, paddingRight: 5}}>Million Acres Strategy Treatments</Text>
                                            </Checkbox>
                                        </Col>
                                        {/* <Col span={3}>
                                            <div style={rootStyle}>
                                                <Tooltip title={'Remove From \n Workspace'} placement="right" >
                                                    <Button icon={<CloseOutlined />} size={"small"}
                                                        onClick={event => removeDatasetFromWorkspace(event, item.mapping[0])}></Button>
                                                </Tooltip>
                                            </div>
                                        </Col> */}
                                    </Row>
                                    <div style={{paddingLeft: '26px'}}>
                                        <Row align="middle" style={{marginBottom: -6}}>
                                            <Col span={8}>
                                                <Text style={{fontSize: 13}}>Opacity</Text>
                                            </Col>
                                            <Col span={14}>
                                                <Slider style={{width: '160px'}}
                                                        min={0}
                                                        max={1}
                                                        step={0.01}
                                                        value={opacities[item.mapping[0].url + "/" + item.mapping[0].layer]}
                                                        onChange={value => onITSSliderChange(value)}
                                                        disabled={!props.checkedDatasets[item.mapping[0].url + "/" + item.mapping[0].layer]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row align="middle">
                                            <Col span={8}>
                                                <Text style={{fontSize: 13}}>Activity End</Text>
                                            </Col>
                                            <Col span={14}>
                                                <Slider range
                                                        style={{width: '150px'}}
                                                        trackStyle={
                                                            props.checkedDatasets[item.mapping[0].url + "/" + item.mapping[0].layer] ? {backgroundColor: item.mapping[0].color} : {}
                                                        }
                                                        handleStyle={{borderColor: 'gray'}}
                                                        step={1}
                                                        max={item.mapping[0].max}
                                                        min={item.mapping[0].min}
                                                        value={props.valueRanges[item.mapping[0].url + "/" + item.mapping[0].layer]}
                                                        tooltip={{formatter: date_formatter}}
                                                        onChange={value => onValueRangeChange(value, item.mapping[0])}
                                                        disabled={!props.checkedDatasets[item.mapping[0].url + "/" + item.mapping[0].layer]}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    {/* {
                                        i < selectedDatasets.filter(item => item.folder === '/Interagency Tracking System').length - 1 ?
                                            <Divider style={{
                                                margin: '5px 0px 10px 0px',
                                                borderTop: '1px solid lightgray'
                                            }}/> : null

                                    } */}
                                </div> 
                            : null
                            ))
                        }

                        {
                            isITSLayersActive() ?
                                <div style={{width: '100%', textAlign: 'center'}}>
                                    <Divider style={{
                                        margin: '5px 0px 10px 0px',
                                        borderTop: '1px solid lightgray'
                                    }}/>
                                    <Button type="primary"
                                            style={{backgroundColor: '#1F7D1F', borderColor: "white"}}
                                            onClick={() => setShowMiniITSDashboard(!showMiniITSDashboard)}
                                    >
                                        {!showMiniITSDashboard ? "More Filters" : "Fewer Filters"}
                                    </Button>
                                    {
                                        showMiniITSDashboard ?
                                            <MiniITSDashboard innerRef={itsDashboardRef}
                                                              valueRanges={getValidValueRanges()}
                                                              categories={props.categories}
                                                              setCategories={props.setCategories}
                                                              getCategoryValue={getCategoryValue}
                                                              owners={props.owners}
                                                              setOwners={props.setOwners}
                                                              getOwnerValue={getOwnerValue}
                                                              agencies={props.agencies}
                                                              setAgencies={props.setAgencies}
                                                              getAgencyValue={getAgencyValue}
                                            />
                                            : null
                                    }
                                </div>
                                :
                                null
                        }
                    </Panel>
                </Collapse>

                {/* ******************** Boundaries Panel ******************* */}
                {
                    selectedDatasets.filter(item => item.folder === '/Boundaries').length > 0 ?
                        <Collapse collapsible="icon" defaultActiveKey={['1']}>
                            <Panel header="Boundaries" key="1"
                                   style={{fontWeight: 'bold', backgroundColor: '#EEEDE3'}}>
                                {
                                    selectedDatasets.filter(item => item.folder === '/Boundaries').map((item, i) =>
                                        <div key={'boundary' + i} style={{fontWeight: 'normal'}}>
                                             <Row>
                                                <Col span={21}>
                                                    <Checkbox
                                                        checked={props.checkedDatasets[item.mapping[0].url + "/" + item.mapping[0].layer]}
                                                        onChange={event => handleCheckboxChange(event, item.mapping[0])}>
                                                        <Text strong
                                                            style={{fontSize: 13, paddingRight: 5}}>{item.mapping[0].name}</Text>
                                                    </Checkbox>
                                                </Col>
                                                {/* <Col span={3}>
                                                    <div style={rootStyle}>
                                                        <Tooltip title={'Remove From \n Workspace'} placement="right" >
                                                            <Button icon={<CloseOutlined />} size={"small"}
                                                                onClick={event => removeDatasetFromWorkspace(event, item.mapping[0])}></Button>
                                                        </Tooltip>
                                                    </div>
                                                </Col> */}
                                            </Row>
                                            <div style={{paddingLeft: '26px'}}>
                                                <Row align="middle" style={{marginBottom: -6}}>
                                                    <Col span={8}>
                                                        <Text style={{fontSize: 13}}>Opacity</Text>
                                                    </Col>
                                                    <Col span={14}>
                                                        <Slider style={{width: '160px'}}
                                                                min={0}
                                                                max={1}
                                                                step={0.01}
                                                                value={opacities[item.mapping[0].url + "/" + item.mapping[0].layer] ?? .7}
                                                                onChange={value => onSliderChange(value, item.mapping[0])}
                                                                disabled={!props.checkedDatasets[item.mapping[0].url + "/" + item.mapping[0].layer]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                            {
                                                getCheckedDatasetsForReport().length > 0 && item.report.disabled !== true ?
                                                    <div style={{width: '100%', textAlign: 'center'}}>
                                                        <Button type="primary"
                                                                style={{
                                                                    backgroundColor: props.checkedDatasets[item.mapping[0].url + "/" + item.mapping[0].layer] ? '#1F7D1F' : 'lightgray',
                                                                    borderColor: "white",
                                                                    marginTop: 10,
                                                                    marginLeft: 20,
                                                                }}
                                                                onClick={() => {
                                                                    setSelectedBoundaryDataset(item);
                                                                    setSelectedUserLayer(null);
                                                                    setOpenModal(true);
                                                                }}
                                                                disabled={ !props.checkedDatasets[item.mapping[0].url + "/" + item.mapping[0].layer]}
                                                        >
                                                            Create Report
                                                        </Button>
                                                    </div>
                                                    :
                                                    null
                                            }


                                            {
                                                i < selectedDatasets.filter(item => item.folder === '/Boundaries').length - 1 ?
                                                    <Divider style={{
                                                        margin: '14px 0px 14px 0px',
                                                        borderTop: '1px solid lightgray'
                                                    }}/> : null

                                            }
                                        </div>
                                    )}

                            </Panel>
                        </Collapse>
                        : null
                }

                {/* ******************** User Provided Data ******************* */}
                {
                    props.layersByUser.length > 0 ?
                        <Collapse collapsible="icon" defaultActiveKey={['1']}>
                            <Panel header="User Provided Data" key="1"
                                   style={{fontWeight: 'bold', backgroundColor: '#EEEDE3'}}>
                                {
                                    props.layersByUser.map((layer, i) =>
                                        <div key={'user-data-' + i}>
                                            <div style={{fontWeight: 'normal'}}>
                                                <Row align="middle" style={{marginBottom: 0, width: '100%'}}>
                                                    <Col span={50}>
                                                        <Checkbox
                                                            checked={userLayerChecked[i]}
                                                            onChange={event => {
                                                                layer.setVisible(!layer.getVisible());
                                                                let newUserLayerChecked = [...userLayerChecked];
                                                                newUserLayerChecked[i] = !userLayerChecked[i];
                                                                setUserLayerChecked(newUserLayerChecked);
                                                            }}>
                                                            <Text strong
                                                                  style={{
                                                                      fontSize: 13,
                                                                      paddingRight: 5
                                                                  }}>{layer.get('name')}</Text>
                                                        </Checkbox>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {
                                                getCheckedDatasetsForReport().length > 0 ?
                                                    <div style={{width: '100%', textAlign: 'center'}}>
                                                        <Button type="primary"
                                                                style={{
                                                                    backgroundColor: userLayerChecked[i] ? '#1F7D1F' : 'lightgray',
                                                                    borderColor: "white",
                                                                    marginTop: 10,
                                                                    marginLeft: 20,
                                                                }}
                                                                onClick={() => {
                                                                    setSelectedUserLayer(layer);
                                                                    setSelectedBoundaryDataset(null);
                                                                    setOpenModal(true);
                                                                }}
                                                                disabled={!userLayerChecked[i]}
                                                        >
                                                            Create Report
                                                        </Button>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                i < props.layersByUser.length - 1 ?
                                                    <Divider style={{
                                                        margin: '15px 0px 15px 0px',
                                                        borderTop: '1px solid lightgray'
                                                    }}/> : null

                                            }

                                        </div>
                                    )}

                            </Panel>
                        </Collapse>
                        : null
                }
                {/* {
                    isFilterActive() ? 
                        <Collapse collapsible="icon" defaultActiveKey={['1']}>
                            <Panel header="Intersection Layers" key="1"
                                style={{fontWeight: 'bold', backgroundColor: '#EEEDE3'}}>
                                {
                                    props.queryLayerInfo.map((item, i) => (
                                        item.mapping[0].type === 'vector' && item.mapping[0].layerId  ?
                                            <div key={'filter' + i} style={{fontWeight: 'normal'}}>
                                                <Row>
                                                    <Col span={21}>
                                                    <Checkbox
                                                        style={{
                                                            "--background-color": '#0014EC',
                                                            "--border-color": '#0014EC',
                                                        }}  
                                                     checked={props.checkedQuerysets[item.mapping[0].layerId]}
                                                        onChange={event => handleQueryCheckboxChange(event, item.mapping[0])}>
                                                        <Text strong
                                                        style={{fontSize: 13, paddingRight: 5}}>{item.mapping[0].name}</Text>
                                                        <Tooltip title={item.mapping[0].tooltip} placement='top'>
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                        
                                                    </Checkbox>
                                                    </Col>
                                                    <Col span={3}>
                                                        <div style={rootStyle}>
                                                            <Tooltip title={'Remove From \n Workspace'} placement="right" >
                                                                <Button icon={<CloseOutlined />} size={"small"} 
                                                                    onClick={event => removeQueryFromWorkspace(event, item)}></Button>
                                                            </Tooltip>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                
                                                {
                                                    i < props.queryLayerInfo.length - 1 ?
                                                        <Divider style={{
                                                            margin: '5px 0px 10px 0px',
                                                            borderTop: '1px solid lightgray'
                                                        }}/>
                                                        : null
                                                }
                                            </div> : null
                                    ))
                                            }
                            </Panel>
                        </Collapse>
                    : null
                }                 */}
            </Space>
            <Modal
                title={modalTitle}
                open={openModal}
                onCancel={hideModal}
                okText="OK"
                cancelText="Cancel"
                width={900}
                footer={<div textAlign='left'>* All text in this report has been created by conversational AI. You can edit the data title and description.</div>}               
            >
                <div id="info" style={{maxHeight: '50vh', minHeight: 300, overflow: 'auto', marginTop: -10}}>
                    {
                        selectedUserLayer ?
                            <UserDataReport selectedUserLayer={selectedUserLayer}
                                            checkedDatasets={getCheckedDatasetsForReport()}
                                            layers={props.layers}
                            /> : null
                    }
                    {
                        selectedBoundaryDataset ?
                            <WFSDataReport selectedFeatureLayer={selectedBoundaryDataset}
                                           checkedDatasets={getCheckedDatasetsForReport()}
                                           layers={props.layers}
                            /> : null
                    }
                </div>
            </Modal>
            <Modal
                title={(unitModalTitle + ' Units')}
                open={openUnitsModal}
                onCancel={() => setOpenUnitsModal(false)}
                footer={null}               
            >
                <div>
                    <span dangerouslySetInnerHTML={{__html: unitsForModal}}></span>
                </div>
            </Modal>

        </div>
    );
}

export default ForestDataMapLayerTree
