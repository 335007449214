import React, {useEffect, useState, useContext} from 'react';
import {Layout, Menu, Typography} from 'antd';
import {AppstoreOutlined, DatabaseOutlined} from '@ant-design/icons';
import ForestDataLogin from '../../map/ForestDataMapLogin';
import {UserContext} from '../../UserContext';
import './header.css';
import {debounce} from "lodash" 
import { none } from 'ol/centerconstraint';


const {Header} = Layout;
const {Title} = Typography;

export default function ForestDataHubHeader(props) {

    const wide_items = [
        {
            label: 'Map Workspace',
            key: 'map',
            icon: <AppstoreOutlined/>,
        },
        {
            label: 'RRK Data Catalog',
            key: 'data',
            icon: <DatabaseOutlined/>
        }
    ];

    const small_items = [
        {
            // label: '',
            key: 'map',
            icon: <AppstoreOutlined/>,
        },
        {
            // label: '',
            key: 'data',
            icon: <DatabaseOutlined/>
        }
    ];

    const [current, setCurrent] = useState('map');
	const {user, setUser} = useContext(UserContext);
    const [items, setItems] = useState(wide_items)

    const onClick = (e) => {
        setCurrent(e.key);
        props.setDisplay(e.key);
    };

    useEffect(() => {
        setCurrent(props.display)
    }, [props.display]);

    const handleResize = debounce(async() => {
        if(window.innerWidth < 900){
            await setItems(small_items);
            document.getElementById('top_menu').style.width = "150px"
        }else{
            await setItems(wide_items);
            document.getElementById('top_menu').style.width = "375px"
        }

    }, 250)

	function updateUser(u){
        console.log(u)
		setUser(u)
	}

    window.addEventListener('resize', handleResize)

    return (
        <div style={{flexGrow: 1, borderBottom: 10, borderColor: 'black'}}>

            <Header
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#525335',
                    height: 50,
                    width: '100%',
                }}
            >
                <Title level={5} style={{color: 'white', marginTop: 10, marginLeft: -25}}>
                    Wildfire and Landscape Resilience Data Hub Explorer
                </Title>
				<Menu style={{
						marginLeft: 'auto',
						marginRight: 0,
						paddingTop: 3,
						paddingBottom: 3,
						alignItems: 'center',
						float: 'right',
						width: 375,
						height: 50,
                        borderBottom: 0,
						backgroundColor: '#525335', //#D8E1D9 #EEEDE3
						color: '#525331',
						fontWeight: 'bold'
					}}
                    id='top_menu'
                    theme='light'
					onClick={onClick}
					selectedKeys={[current]}
					mode="horizontal"
					items={Object.keys(user).length === 0 || user?.access_token === undefined ? '' : items}/>

                <ForestDataLogin user={user} updateUser={updateUser}></ForestDataLogin>                
            </Header>
        </div>
    );
}
