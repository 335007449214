import chroma from 'chroma-js';
import config from './config.js';
import fetch from 'sync-fetch';

const getSLDForLayer = (layer_name, color_start, color_end, max, min, max_display, min_display) => {

    console.log("layer_name")
    console.log(layer_name)
    console.log("max_display")
    console.log(max_display)
    console.log("min_display")
    console.log(min_display)
    let legend_url = `https://sparcal.sdsc.edu/geoserver/wms?service=WMS&version=1.1.0&request=GetLegendGraphic&layer=${layer_name}&format=application/json`;
    let legend_info = fetch(legend_url).json().Legend[0].rules[0].symbolizers[0].Raster.colormap.entries;
    let color_map_def = legend_info.map(entry => ({'value': Number(entry.quantity), 'color': entry.color}));
    let color_map = color_map_def.filter(entry => entry.value >= min_display && entry.value <= max_display);

    console.log("color_map")
    console.log(color_map)

    let sld = `<?xml version="1.0" encoding="ISO-8859-1"?>
<StyledLayerDescriptor version="1.0.0" xmlns="http://www.opengis.net/sld" xmlns:ogc="http://www.opengis.net/ogc"
    xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xsi:schemaLocation="http://www.opengis.net/sld http://schemas.opengis.net/sld/1.0.0/StyledLayerDescriptor.xsd">
<NamedLayer><Name>${layer_name}</Name><UserStyle><FeatureTypeStyle><Rule><RasterSymbolizer><Opacity>1.0</Opacity><ColorMap>`;

    sld += `<ColorMapEntry color="${color_start}" quantity="${min_display-0.00001}" label="${max_display-0.00001}" opacity="0" />`;

    if (color_map.length > 0 && min_display < color_map[0].value) {
        sld += `<ColorMapEntry color="${color_start}" quantity="${min_display}" label="${max_display}" opacity="1" />`;
    }

    for (let i = 0; i < color_map.length; i++) {
        sld += `<ColorMapEntry color="${color_map[i].color}" quantity="${color_map[i].value}" label="${color_map[i].value}" opacity="1" />`;
    }

    if (color_map.length > 0 && max_display < color_map[color_map.length-1].value) {
        sld += `<ColorMapEntry color="${color_end}" quantity="${max_display}" label="${max_display}" opacity="1" />`;
    }

    sld += `<ColorMapEntry color="${color_end}" quantity="${max_display+0.00001}" label="${max_display+0.00001}" opacity="0" />`;

    console.log(sld)


    sld += `</ColorMap></RasterSymbolizer></Rule></FeatureTypeStyle></UserStyle></NamedLayer></StyledLayerDescriptor>`;
    // console.log(sld);

    return sld;
}


const getSLDForLayer2 = (layer_name, color_start, color_end, max, min, max_display, min_display) => {

    let sld = `<?xml version="1.0" encoding="ISO-8859-1"?>
<StyledLayerDescriptor version="1.0.0" xmlns="http://www.opengis.net/sld" xmlns:ogc="http://www.opengis.net/ogc"
    xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xsi:schemaLocation="http://www.opengis.net/sld http://schemas.opengis.net/sld/1.0.0/StyledLayerDescriptor.xsd">
<NamedLayer><Name>${layer_name}</Name><UserStyle><FeatureTypeStyle><Rule><RasterSymbolizer><Opacity>1.0</Opacity><ColorMap>`;
    let segments = 20;
    var step = 1.0 / segments;
    const gradient = chroma.scale([color_start, color_end]);
    let values_done = [];

    // console.log("min_display", min_display);
    // console.log("max_display", max_display);
    // console.log("min", min);
    // console.log("max", max);

    if (min_display < min + (max - min) * step) {
        sld += `<ColorMapEntry color="${color_start}" quantity="${min_display.toFixed(3)}" label="values" opacity="1" />`;
        values_done.push(`${min_display.toFixed(2)}`);
    }

    for (var i = step; i <= 1 + step; i = i + step) {
        let color = gradient(i).toString();
        let quantity = min + (max - min) * i;
        quantity = Number(quantity.toFixed(3))

        let opacityCondition =
            Math.abs(i - 1) < Number.EPSILON ||
            quantity < min_display ||
            quantity > max_display;

        if (max_display < quantity && (max_display > (min + (max - min) * (i - step)))) {
            let color = gradient(max_display / (max - min)).toString();
            if (values_done.includes(`${max_display}`)) {
                // console.log("should skip ", max_display);
            } else {
                sld += `<ColorMapEntry color="${color}" quantity="${max_display}" label="values" opacity="1" />`;
                values_done.push(`"${max_display}"`);
            }
        }

        if (values_done.includes(`${quantity.toFixed(3)}`)) {
            // console.log("should skip ", quantity.toFixed(2));
        } else {
            sld += `<ColorMapEntry color="${color}" quantity="${quantity.toFixed(3)}" label="values" opacity="${opacityCondition ? 0 : 1}" />`;
            values_done.push(`${quantity.toFixed(2)}`);
        }

        if (min_display > quantity && (min_display < (min + (max - min) * (i + step)))) {
            let color = gradient(min_display / (max - min)).toString();
            if (values_done.includes(`${min_display}`)) {
                // console.log("should skip ", min_display);
            } else {
                sld += `<ColorMapEntry color="${color}" quantity="${min_display}" label="values" opacity="0" />`;
                values_done.push(`${min_display}`);
            }
        }
    }
    sld += `<ColorMapEntry color="#ffffff" quantity="${(max + 0.01).toFixed(3)}" label="values" opacity="0" />`

    sld += `</ColorMap></RasterSymbolizer></Rule></FeatureTypeStyle></UserStyle></NamedLayer></StyledLayerDescriptor>`;
    // console.log(sld);

    return sld;
}
const getLayerConfig = (url, layer) => {
    let list = config["Regional Resource Kit"]["Southern California"];
    for (var j = 0; j < list.length; j++) {
        if (url === list[j].url && list[j].layer === layer) {
            return list[j];
        }
    }
    list = config["Interagency Tracking System"];
    for (j = 0; j < list.length; j++) {
        if (url === list[j].url && list[j].layer === layer) {
            return list[j];
        }
    }
    return null;
}
const getMinMax = () => {
    let ranges = {};
    let list = config["Regional Resource Kit"]["Southern California"];
    for (var j = 0; j < list.length; j++) {
        let key = list[j].url + "/" + list[j].layer;
        ranges[key] = [list[j].min, list[j].max];
    }
    list = config["Interagency Tracking System"];
    for (j = 0; j < list.length; j++) {
        let key = list[j].url + "/" + list[j].layer;
        ranges[key] = [list[j].min, list[j].max];
    }
    return ranges;
}
const getMin = (key) => {
    let list = config["Regional Resource Kit"]["Southern California"];
    for (var j = 0; j < list.length; j++) {
        if (list[j].url + "/" + list[j].layer === key) {
            return list[j].min;
        }
    }
    list = config["Interagency Tracking System"];
    for (j = 0; j < list.length; j++) {
        if (list[j].url + "/" + list[j].layer === key) {
            return list[j].min;
        }
    }
    return null;
}
const getMax = (key) => {
    let list = config["Regional Resource Kit"]["Southern California"];
    for (var j = 0; j < list.length; j++) {
        if (list[j].url + "/" + list[j].layer === key) {
            return list[j].max;
        }
    }
    list = config["Interagency Tracking System"];
    for (j = 0; j < list.length; j++) {
        if (list[j].url + "/" + list[j].layer === key) {
            return list[j].max;
        }
    }
    return null;
}
const getMainAttributes = (features) => {
    if (features) {
        let feature = features[0];
        const properties = feature.getProperties();
        let candidates = [];
        let id = null;
        for (const key in properties) {
            if (key.toLowerCase().endsWith("id")) {
                id = key;
                continue;
            } else if (typeof feature.get(key) !== 'string') {
                continue;
            }
            candidates.push(key);
        }
        if (candidates) {
            if (candidates.length === 1) {
                return [candidates[0]];
            } else {
                // move the name to the first if exists
                let name = candidates.filter(item => item.toLowerCase() === 'name');
                let rest = candidates.filter(item => item.toLowerCase() !== 'name');
                candidates = name.concat(rest);

                for (let i = 0; i < candidates.length; i++) {
                    let candidate = candidates[i]
                    // check the uniqueness of candidate
                    let values = new Set();
                    let unique = true;
                    features.forEach((feature) => {
                        let value = feature.get(candidate);
                        if (values.has(value)) {
                            unique = false;
                        } else {
                            values.add(value);
                        }
                    });
                    if (unique) {
                        return [candidate];
                    }
                }
                ;

                // found no candidate
                if (id) {
                    return [id];
                } else {
                    return candidates;
                }
            }
        } else {
            return [id];
        }
    }
    return [];
}
export {getSLDForLayer, getLayerConfig, getMax, getMin, getMinMax, getMainAttributes};