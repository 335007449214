import React from 'react';
import {useState, useEffect, useContext} from "react";
import {FilterOutlined} from '@ant-design/icons';
import {Modal, Typography, Alert, Avatar, Form, Select, Input, Divider, Button, message, Upload, notification } from "antd";
import 'antd/dist/antd.css';
import './query.css'
import { Space, Table, Tag } from 'antd';
import {LoginOutlined, ImportOutlined, QuestionCircleOutlined, MessageOutlined, CheckCircleOutlined, AppstoreOutlined, DeleteOutlined, UploadOutlined} from '@ant-design/icons';
import { UserContext } from '../UserContext';
import WorkspaceContext from '../WorkspaceContext';

const {Text} = Typography;
const { Column } = Table;
const { TextArea } = Input;
const syncfetch = require('sync-fetch')
const { Title } = Typography;




//create table script
//CREATE TABLE "fdh".useraccounts
// (
// 	userid serial PRIMARY KEY,
// 	username VARCHAR,
// 	"password" VARCHAR
// )

class ForestDataMapFeedback extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            visible : false,
            delete_visible: false,
            workspaceList: [],
            selectedFile: null, 
            error: false,
            errorDescription: null,
            access_token: props.access_token
        };

        this.setAccessToken = this.setAccessToken.bind(this)
        this.toggleHover = this.toggleHover.bind(this);
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.openNotification = this.openNotification.bind(this)
    }

    static contextType = WorkspaceContext

    toggleHover() {
        this.setState({hover: !this.state.hover})
    }

    showModal() {
        this.setState({visible: true})
    }

    hideModal() {
        this.setState({visible: false})
    }

    onFinishFailed = (errorInfo) => {
       this.setState({error: true})
    };

    setAccessToken(token){
        this.setState({"access_token": token})
    }

    openFeedbackModal(){
        this.setState({'visible' : true})
    }

    setSelectedFile(selectedFile){
        this.setState({"selectedFile" : selectedFile })
    }

    openNotification = (placement, message) => {
        notification.info({
            message: message,
            icon: <CheckCircleOutlined />,
            style: {color: 'green'},
            placement,
        });
    };

    sendFeedback = async (info) =>{
        let base_url = process.env.REACT_APP_API_URL

        console.log(info)

        let formData = new FormData()
        formData.append('feedback_text', info['feedback_text'])
        formData.append('access_token', this.props.access_token)
        formData.append("feedback_topic", info['feedback_topic'])

        console.log("Bearer " + this.props.access_token)

        const response = await fetch(`${base_url}/feedback/feedback/${info['feedback_text']}/topic/${info['feedback_topic']}`, 
        {
            method: "POST",
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': "Bearer " + this.props.access_token
            }
        })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));

        console.log(response)

        if(response['status'] === 'failed'){
            this.setState({
                errorDescription: response['message'],  
                error: true
            })
        }else{
            this.openNotification('top', response['message'])
            this.hideModal()
        }
    }

    render() {

        let rootStyle = this.props.style;
        if (rootStyle === undefined) {
            rootStyle = {
                position: 'absolute',
                top: '196px',
                left: '7px',
                padding: '1px 4px 1px 4px',
                borderRadius: '6px',
                backgroundColor: this.state.hover ? 'rgba(0, 60, 136, .8)' : 'rgba(0, 60, 136, .5)',
                border: '3px solid rgba(255, 255, 255, 0.72)',                
            };
        }

        let topics = [            
            {
                label: 'Map Workspace',
                value: 'map'
            },
            {
                label: 'RRK Data Catalog',
                value: 'data_tab'
            },
            {
                label: 'Feature and Function Suggestions',
                value: 'add_features'
            },
            {
                label: 'Other',
                value: 'other'
            }
        ]

       return ( 
        <div>
            <div>
                <a onClick={() => this.openFeedbackModal()} style={{color: 'black'}}>
                    <MessageOutlined style={{paddingRight: "5px"}}></MessageOutlined>Feedback
                </a>
            </div>
            <Modal title="Share your feedback"
                open={this.state.visible}
                onCancel={() => this.setState({"visible" : false})}
                destroyOnClose={true}
                footer={null}
                width={700}
                // style={
                //     {
                //         maxHeight: "300px"
                //     }
                // }
                >
                <div>
                {this.state.error ? (<Alert style={{marginBottom: "15px"}}
                        message={this.state.errorDescription ?? "Please fill in all required fields."}
                        type="error"
                        closable
                        showIcon
                        />) : null}
                    <Form
                        name="feedback"
                        wrapperCol={{
                            span: 24,
                        }}
                        layout="vertical"
                        onFinish={this.sendFeedback}
                        onFinishFailed={this.onFinishFailed}
                        autoComplete="off"
                        scrollToFirstError="true"
                        fields={[{
                            name: 'access_token',
                            value: this.props.access_token
                        }
                        ]}
                        >     
                        <Form.Item
                        name="access_token" style={{ display: 'none' }} >
                            <Input></Input>
                        </Form.Item> 
                        <Form.Item  name='feedback_topic' label='Select Topic of Feedback'>
                            <Select
                                style={{
                                    width: 300,
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a topic',
                                    },
                                ]}
                                placeholder="Choose a feedback topic"
                                options={topics}
                            />
                        </Form.Item>                  
                        <Form.Item
                            label="Please share your feedback on the Data Hub below: "
                            name="feedback_text"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your feedback',
                                },
                            ]}
                            style={{
                                marginBottom:'5px'
                            }}
                            >
                            <TextArea rows={12} />
                        </Form.Item>
                        <div style={{fontSize:"9px", marginBottom: "9px"}}>* Your user data will be submitted with your feedback.</div>
                        
                        {/* <Form.Item 
                        name="uploaded_image"
                        label="Upload an Image to provide specificity (optional)">
                            <Upload {...view_props}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item> */}
                        <Form.Item style={{textAlign: 'center'}}>
                            <Button type="primary" htmlType="submit" >
                                Send
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </div>
    )}

}


export default ForestDataMapFeedback;