import React, { useState } from 'react';
import {HomeOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import { Tooltip } from 'antd';


const ForestDataMapReset = (props) => {

    const [hover, setHover] = useState(false);

    const toggleHover = () => {
        setHover(!hover);
    }

    const onReset = () => {
        let view = props.map.getView();
        //view.setCenter([-118.4194, 33.9592]);  // southern CA
        view.setCenter([-119.4179, 36.7783]);
        // view.setZoom(7.8);
        view.setZoom(6.2);

        for (var i = 0; i < props.layers.length; i++) {
            let layer = props.layers[i];
            if (layer.getVisible()) {
                layer.getSource().refresh();
            }
        }
    }

    let rootStyle = props.style;
    if (rootStyle === undefined) {
        rootStyle = {
            ...rootStyle,
            position: 'absolute',
            top: '98px',
            left: '7px',
            padding: '1px 4px 0px 4px',
            borderRadius: '6px',
            backgroundColor: hover ? 'rgba(0, 60, 136, .8)' : 'rgba(0, 60, 136, .5)',
            border: '3px solid rgba(255, 255, 255, 0.72)',
        };
    }

    return (
        <div>
            <div style={rootStyle} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                <Tooltip title={"Reset Map"} placement='right'>
                    <HomeOutlined onClick={onReset} style={{color: 'white'}}/>
                </Tooltip>
            </div>
        </div>
    );
}


export default ForestDataMapReset;
