import React, {PureComponent} from 'react';
import {BarChart, Bar, Cell, Tooltip, ResponsiveContainer} from 'recharts';
import {StarFilled} from '@ant-design/icons';
import {Typography, Row, Col} from 'antd';
import {debounce} from "lodash";
import {Checkbox} from 'antd';
import axios, * as others from 'axios';

const {Text} = Typography;


const ownerColorMap = {
    "Federal": '#5F5D5B',
    "Local": '#5F5D5B',
    "NGO": '#5F5D5B',
    "Private Industry": '#5F5D5B',
    "Private Non-Industry": '#5F5D5B',
    "State": '#5F5D5B',
    "Tribal": '#5F5D5B',
    "Not Reported" : '#5F5D5B'
}

// const getOwnerValue = (owner) => {
//     if (owner === "Federal") {
//         return "FEDERAL";
//     } else if (owner === "Local") {
//         return "LOCAL";
//     } else if (owner === "NGO") {
//         return "NGO";
//     } else if (owner === "Private Industry") {
//         return "PRIVATE_INDUSTRY";
//     } else if (owner === "Private Non-Industry") {
//         return "PRIVATE_NON-INDUSTRY";
//     } else if (owner === "State") {
//         return "STATE";
//     } else if (owner === "Tribal") {
//         return "TRIBAL";
//     } else if(owner === 'Not Reported') {
//         return '';
//     }
//     else {
//         return null;
//     }
// }

const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length) {
        return (
            <div style={{
                backgroundColor: 'white',
                border: '1px solid lightgray',
                fontSize: '12px',
                fontWeight: 'normal',
                fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
                padding: 6
            }}>
                {`${payload[0].payload.owner} : ${payload[0].payload.acres}`}
            </div>
        );
    }

    return null;
};



export default class AcresByOwner extends PureComponent {

  constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
            
    getAcres = (owner, data_list) => {
        let ownerValue = this.props.getOwnerValue(owner)
        for (let i = 0; i < data_list.length; i++) {
            if (ownerValue === data_list[i][0]) {
                return data_list[i][1];
            }
        }
        return 0;
    }    

    handleClick = (data, index) => {
        console.log(this.props)
        let newData = [...this.props.ownerData];
        newData = newData.map(item => {
            if (item.owner === data.owner) {
                return {
                    ...item,
                    visible: !item.visible,
                    acres: !item.visible ? item.acres : 0
                }
            } else {
                return item;
            }
        });        
        // this.setState({
        //     data: newData
        // });

        this.props.setOwnerData(newData)

        this.props.setOwners(newData.filter(item => item.visible === true).map(item => item.owner));
    };

    // prevent too many requests generated by the sliders
    debounceLog = debounce(async valueRanges => {

        this.setState({'loading': true})        
        // console.log("its loading")
        // console.log(valueRanges)

        let data_collections = [];
        let returned_owners = [];
        var count = 0;
        for (const wms_url in valueRanges) {
            let elements = wms_url.split('/');
            let wfs_name = elements[elements.length - 1];

            let min = valueRanges[wms_url][0];
            let max = valueRanges[wms_url][1];

            let visible_cat = this.props.categoryData.filter(e => e.visible).map(e => this.props.getCategoryValue(e.category))
            let visible_cat_str = "'" + visible_cat.join("', '") + "'"

            let visible_owner = this.props.ownerData.filter(e => e.visible).map(e => this.props.getOwnerValue(e.owner))
            let visible_owner_str = "'" + visible_owner.join("', '") + "'"

            let visible_agency = this.props.agencyData.filter(e => e.visible).map(e => this.props.getAgencyValue(e.agency))
            let visible_agency_str = "'" + visible_agency.join("', '") + "'"

            let cql = `activity_end >= '${min * 100000}' `;
            cql += ` AND activity_end <= '${max * 100000}' `;
            cql += ` AND activity_cat IN (${visible_cat_str})`
            cql += ` AND primary_ownership_group IN (${visible_owner_str})`
            cql += ` AND agency IN (${visible_agency_str})`
            cql += ` AND primary_ownership_group is not null`;
            cql += ` AND agency is not null`;
            cql += ` AND activity_cat is not null`;

            var xmlBodyStr = `<?xml version="1.0" encoding="UTF-8"?><wps:Execute xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0.0" service="WPS" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
  <ows:Identifier>gs:Aggregate</ows:Identifier>
  <wps:DataInputs>
    <wps:Input>
      <ows:Identifier>features</ows:Identifier>
      <wps:Reference mimeType="text/xml" xlink:href="http://geoserver/wps" method="POST">
        <wps:Body>
          <wps:Execute xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0.0" service="WPS" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
            <ows:Identifier>gs:Query</ows:Identifier>
            <wps:DataInputs>
              <wps:Input>
                <ows:Identifier>features</ows:Identifier>
                <wps:Reference mimeType="text/xml" xlink:href="http://geoserver/wfs" method="POST">
                  <wps:Body>
                    <wfs:GetFeature xmlns:ITS="https://sparcal.sdsc.edu/geoserver/ITS" service="WFS" version="1.0.0" outputFormat="GML2">
                      <wfs:Query typeName="${wfs_name}"/>
                    </wfs:GetFeature>
                  </wps:Body>
                </wps:Reference>
              </wps:Input>
              <wps:Input>
                <ows:Identifier>attribute</ows:Identifier>
                <wps:Data>
                  <wps:LiteralData>primary_ownership_group</wps:LiteralData>
                </wps:Data>
              </wps:Input>
              <wps:Input>
                <ows:Identifier>attribute</ows:Identifier>
                <wps:Data>
                  <wps:LiteralData>activity_quantity</wps:LiteralData>
                </wps:Data>
              </wps:Input>
              <wps:Input>
                <ows:Identifier>filter</ows:Identifier>
                <wps:Data>
                  <wps:ComplexData mimeType="text/plain; subtype=cql"><![CDATA[${cql}]]></wps:ComplexData>
                </wps:Data>
              </wps:Input>
            </wps:DataInputs>
            <wps:ResponseForm>
              <wps:RawDataOutput mimeType="application/json">
                <ows:Identifier>result</ows:Identifier>
              </wps:RawDataOutput>
            </wps:ResponseForm>
          </wps:Execute>
        </wps:Body>
      </wps:Reference>
    </wps:Input>
    <wps:Input>
      <ows:Identifier>aggregationAttribute</ows:Identifier>
      <wps:Data>
        <wps:LiteralData>activity_quantity</wps:LiteralData>
      </wps:Data>
    </wps:Input>
    <wps:Input>
      <ows:Identifier>function</ows:Identifier>
      <wps:Data>
        <wps:LiteralData>Sum</wps:LiteralData>
      </wps:Data>
    </wps:Input>
    <wps:Input>
      <ows:Identifier>singlePass</ows:Identifier>
      <wps:Data>
        <wps:LiteralData>True</wps:LiteralData>
      </wps:Data>
    </wps:Input>
    <wps:Input>
      <ows:Identifier>groupByAttributes</ows:Identifier>
      <wps:Data>
        <wps:LiteralData>primary_ownership_group</wps:LiteralData>
      </wps:Data>
    </wps:Input>
  </wps:DataInputs>
  <wps:ResponseForm>
    <wps:RawDataOutput mimeType="application/json">
      <ows:Identifier>result</ows:Identifier>
    </wps:RawDataOutput>
  </wps:ResponseForm>
</wps:Execute>`;

            var config = {
                headers: {
                    'Content-Type': 'text/plain',
                    // "Access-Control-Allow-Origin": "*",
                    "Accept": "application/json"
                },
            };

            await axios.post('https://sparcal.sdsc.edu/geoserver/wps', xmlBodyStr, config)
                .then(res => {
                    let resultList = res.data.AggregationResults;                 
                    resultList.map(item => item[0]).forEach(
                        item => {
                            if (!returned_owners.includes(item)) {
                                returned_owners.push(item);
                            }
                        }
                    )
                    data_collections.push(resultList);
                    count += 1;
                    if (count === Object.keys(valueRanges).length) {
                        let newData = [...this.props.ownerData];
                        let madeChanges = false;
                        newData = newData.map(item => {
                            let acres = 0;
                            for (let i = 0; i < data_collections.length; i++) {
                                acres += this.getAcres(item.owner, data_collections[i]);
                            }
                            if (Math.ceil(item.acres) !== Math.ceil(acres)) {
                                madeChanges = true;
                            }
                            return {
                                owner: item.owner,
                                acres: Math.ceil(acres),
                                visible: item.visible,
                            }
                        });

                        // console.log(JSON.stringify(newData, null, 2));
                        if (madeChanges) {
                            // this.setState({
                            //     data: newData
                            // });
                            this.props.setOwnerData(newData)
                        }
                    }
                })
                .catch(err => {
                    console.log(JSON.stringify(err));
                })

          }
              
          this.setState({'loading': false})        

    }, 1000);

    componentDidMount() {
      this.debounceLog(this.props.valueRanges);
    }

    componentDidUpdate(prevProps) {

      let prevVisibleAgencies = prevProps.agencyData.filter(e => e.visible).map(e => e.agency)
      prevVisibleAgencies.sort()
      let currentVisibleAgencies = this.props.agencyData.filter(e => e.visible).map(e => e.agency)
      currentVisibleAgencies.sort()
      let prevVisibleCategories = prevProps.categoryData.filter(e => e.visible).map(e => e.category)
      prevVisibleCategories.sort()
      let currentVisibleCategories = this.props.categoryData.filter(e => e.visible).map(e => e.category)
      currentVisibleCategories.sort()

      let prevVisibleOwners = prevProps.ownerData.filter(e => e.visible).map(e => e.owner)
      prevVisibleOwners.sort()
      let currentVisibleOwners = this.props.ownerData.filter(e => e.visible).map(e => e.owner)
      currentVisibleOwners.sort()

      if(JSON.stringify(prevVisibleAgencies) !== JSON.stringify(currentVisibleAgencies) 
        || JSON.stringify(currentVisibleCategories) !== JSON.stringify(prevVisibleCategories)
        || prevVisibleOwners.length < currentVisibleOwners.length){
          this.debounceLog(this.props.valueRanges);
      }
    }    

    render() {
        // const {data} = this.state;

        let total_acres = 0
        this.props.ownerData.forEach(item => {
            total_acres += item.acres
        })

        return (
          <div>
            {this.state.loading 
            ? 
              <div> Loading ... </div>
            : 
              <div style={{width: '100%', marginTop: 0}}>
                {/* <ResponsiveContainer width="100%" height={200}>
                    <BarChart width={300} height={160} data={data}>
                        <Bar dataKey="acres"
                             label={{
                                 position: "top",
                                 style: {fontSize: 8},
                                 fill: "gray",
                             }}
                             onMouseOver={(data) => {
                                 this.setState({
                                     barGraphData: data
                                 })
                             }}
                             onClick={this.handleClick}>
                            {data.map((entry, index) => (
                                <Cell cursor="pointer"
                                      fill={
                                          data[index].visible === true ?
                                              ownerColorMap[data[index].owner]
                                              :
                                              '#dddddd'
                                      }
                                      key={`cell-${index}`}/>
                            ))}
                        </Bar>
                        <Tooltip cursor={false}
                                 // position={{y: this.state.barGraphData.y - 60}}
                                 position={{y: 0}}
                                 content={<CustomTooltip/>}/>
                    </BarChart>
                </ResponsiveContainer> */}
                {/* <table style={{
                    fontSize: 11,
                    fontWeight: 'normal',
                    textAlign: 'left',
                    marginLeft: 1,
                    marginTop: 10,
                    width: "100%",
                    id: 'owner_table'
                }}>
                    <tbody>
                    {
                        this.props.ownerData.sort((a, b) => (b.acres - a.acres)).map(item =>
                            <tr key={item.owner}>
                                <td><Checkbox onClick={() => this.handleClick(item)} checked={item.visible} style={{paddingRight: 5}}></Checkbox></td>
                                <td style={{paddingRight: 5}}>{item.owner}</td>
                                <td style={{float: 'right'}}>{item.acres}</td>
                            </tr>
                        )
                    }
                    <tr key="total">
                            <td></td>
                            <td style={{paddingRight: 5}}>Total Acres</td>
                            <td style={{float: 'right'}}>{total_acres}</td>
                        </tr>
                    </tbody>
                </table> */}
                 {
                    this.props.ownerData.sort((a, b) => (b.acres - a.acres)).map(item =>
                            <Row style={{border: '5px'}}>
                              <Col span={20}>
                                <Checkbox onClick={() => this.handleClick(item)} checked={item.visible} style={{paddingRight: 5, fontWeight: 'normal', fontSize: '13px'}}>{item.owner}</Checkbox>
                              </Col>
                              <Col span={4}>
                                <div style={{float: 'right', fontSize: '13px', fontWeight: 'normal'}}>{item.acres.toLocaleString()}</div>
                              </Col>
                            </Row>
                        )
                  }
                  <Row style={{marginTop: '10px'}}>
                    <Col span={20} style={{fontSize: '13px'}}>
                      Total Acres
                    </Col>
                    <Col span={4}>
                      <div style={{float: 'right', fontSize: '13px', fontWeight: 'normal'}}>{total_acres.toLocaleString()}</div>
                    </Col>
                  </Row>
              </div>
          }
          </div>
        );
    }
}


